@font-face {
  font-family: "AccordAlternate";
  src: url('/assets/fonts/AccordAlternate.otf')
}
@font-face {
  font-family: "AccordAlternateMedium";
  src: url('/assets/fonts/AccordAlternate-Medium.otf')
}
@font-face {
  font-family: "AccordAlternateBold";
  src: url('/assets/fonts/AccordAlternate-Bold.otf')
}
@font-face {
  font-family: "AccordAlternateLight";
  src: url('/assets/fonts/AccordAlternate-Light.otf')
}

*:focus, *:focus-visible, *:focus-within {
  outline: none !important;
  box-shadow: none !important;
}

body {
  background-color: white !important;
  overflow-x: hidden;
  width: 100%;
}

section {
  padding-top:60px!important;
  padding-bottom:60px!important;
  margin-top:0!important;
  margin-bottom:0!important;
}

a{
  //color: #000 !important;
  text-shadow: none;
}
.ui-page-theme-a .ui-btn, html .ui-bar-a .ui-btn, html .ui-body-a .ui-btn, html body .ui-group-theme-a .ui-btn, html head+body .ui-btn.ui-btn-a, .ui-page-theme-a .ui-btn:visited, html .ui-bar-a .ui-btn:visited, html .ui-body-a .ui-btn:visited, html body .ui-group-theme-a .ui-btn:visited, html head+body .ui-btn.ui-btn-a:visited{
  text-shadow: none !important;
}

.lightbox-on-second img.active {
  object-fit: cover;
  position: fixed;
  width: 75vw;
  height: 80vh;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
}

li{
  list-style: none;
}

.tracol-logo {
  margin-left:50px;
  margin-right:150px;
}


.sticky-text{
  position: absolute;
  //top: 50vh;
  //left: calc(50vw - 132px);
  padding: 10px;
  text-align: center;
  width: fit-content;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.9);
  border: 2px solid rgba(255, 255, 255, 0.6);
  background-color: rgba(0, 0, 0, 0.3);
  //border-radius: 10px;
  backdrop-filter: blur(5px);
}

nav{
  &.navbar.navbar-expand-xl {
    background-color: #000;

    .container-fluid{
      flex-wrap: wrap;
    }

    #center-nav {
      display: flex;
      align-items: center;
      margin-right: 75px;

      .logo {
        margin: 30px 4vw;
      }

      .navbar-toggler {
        background-color: transparent !important;
        border-color: white !important;
        margin-left: 20px;
        width: auto;

        &.collapsed {
          //position: absolute;
          //left: 34px;
          //top: 17px;
        }
      }

      .nav-elements{

      }

      .phone-contact {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 auto;

        .phone{
          white-space: nowrap;
          margin: 0;
        }
      }
    }
  }
}

.btn{
  &.btn-primary{
    &.child{
      background-color: white;
      color: #C7C7C7;
      width: 100%;
      border:none;
      border-radius: 0;

      &.search {
        font-size: 16px;
        font-family: "AccordAlternate", "serif";
        color:#5f5f5f;

        &:hover {
          font-size: 16px;
          width: 100%;
          font-weight: bold;
        }
      }
    }

    &.button-newsletter {
      background-color: #f6a400;
      border-color: white;
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 22px;
    }

    &.come.border-radius{
      transition: all .3s;
      width: 100%;
      min-width: fit-content;

      &:hover {
        background-color: #5f5f5f !important;
        border-color: #5f5f5f !important;
        height: 79px;
        margin-top: 11px;
        font-family: "AccordAlternateBold", "serif";

        .span-projects {
          color: white;
          font-family: "AccordAlternateBold", "serif";
          text-transform: uppercase;
        }
      }
    }
  }
}

.price-detail-bien{
  width: auto !important;
}

.accordion-item{
  background-color: transparent !important;
}

#section-2-project{
  margin-left: 10px;
}

.image-bien{
  width: 100%;
  height: 450px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 9px;
}

.image-sider{
  width: 100%;
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 107px 0 0 30px;
}

.table > :not(caption) > * > * {
  padding: 15px 15px !important;
}

#location-li{
  height: 360px !important;
  overflow-y: auto;
}

.ref-list:not(#references-home .ref-list) {
  width: 413px;
}
#references-home .ref-list {
  width:100%;
}
.tracol-page-img2{
  width: 500px;
}

.dropdown{
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 65px;
  width: 25%;
  padding: 0;
  padding-left: 30px;
  background-color: #fff;


  .undroped, .dropdown-header{
    position: relative;
    z-index: 10;
    margin-top: 7px;
    height: 63px;
    cursor: pointer;
    font-family: "AccordAlternateBold", "serif";
    font-size: 16px;
    text-transform: uppercase;
    text-align: left;
    white-space: nowrap;

    .undroped-header{
      color: #5f5f5f;
      margin-bottom: 0;
      text-align: left;
    }

    .undroped-subheader{
      //padding: 5px 0 0;
      color: #C7C7C7;
      font-family: "AccordAlternate", "serif";
      text-transform: none;
      text-align: left;

      &.selected{
        color: #F6A400;
      }
      &.good-place{
        margin-top: -4px;
      }
    }
  }

  .undroped{

    &::after{
      position: absolute;
      display: block;
      top: 15px;
      right: 16px;
      content: url("/assets/images/arrow-orange.svg");
      transition: all .3s;

    }
  }

  .droped{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: none;
    float: right;
    color: #dedede;
    font-size: 16px;
    font-family: "AccordAlternate", "serif";
    background-color: #ffffff;
    background-image: none;
    border: none;
    -ms-word-break: normal;
    word-break: normal;

    .dropdown-header{
      padding: 0;
    }

    &::after{
      position: absolute;
      display: block;
      width: 20px;
      height: 20px;
      top: 25px;
      right: 16px;
      content: url("");
      background-image: url("/assets/images/arrow-orange.svg");
      background-repeat: no-repeat;
      transition: all .3s;
      //transform: rotate(180deg);

    }

    &.open{
      &::after{
        transform: rotate(180deg);
      }
    }

    .items{
      position: absolute;
      top: 45px;
      height:200px!important;
      overflow: scroll;
      left: 0;
      list-style: none;
      padding: 0;
      background-color: #fff;
      padding: 20px 5px;
      width: 100%;
      z-index: 1;

      .item{
        cursor: pointer;
        margin: 10px 0;
        color: #999;
        text-align: left;
        margin-left: 25px;

        &:hover{
          color: #F6A400;
        }

        &.selected{
          color: #F6A400;
        }

      }
    }
  }
}

#formResearchNav {
  .sub-search-input {
    height:46px;
  }
}

header {
  height: 100vh;
  margin-top: 127px !important;
  background-size:cover;
  background-repeat: no-repeat;
  background-position: center;

  .container-fluid{

    #block-header-home{
      margin-top: calc(35vh - 110px);

      #svg-header{
        margin-bottom: 10px;
      }

      #form-search-normal{
        margin-top: 20vh;

        .selectors{
          display: flex;
          flex-direction: row;
          width: calc(50vw + 200px);

          #submit-search{
            margin: 0;
            padding: 0;
            border: 0;

            .search-button {
              margin-left: 0;

              //opacity: .5;

              #Tracé_95{
                transition: all .3s;
                &:hover{
                  fill: #ff6600;
                }
              }
            }
          }
        }

        .sub-search-div{
          width: calc(100% - 165px);
          height: fit-content;


          .sub-search-input {
            background-color: #5f5f5f;
            color: #999;
            border-color: #5f5f5f;
            border: 0;
            width: 100%;
            min-width: 165px;
            padding: 3px 15px 7px;
            margin: 20px 5px;
            font-size: 15px;
            height:35px;
          }

          .dropdown{
            z-index: 1;
            width: fit-content;
            height: 30px;
            margin: 20px 50px;
            background-color: #5f5f5f;

            .undroped{
              margin-top: 5px;

              .undroped-header{
                color: #999;
              }
            }

            &::after{
              top: 0;
            }

            .dropdown-header, .items, .droped{
              background-color: #5f5f5f;

              .items{
                top: 30px;
              }

              .dropdown-header{
                margin-top: 5px;
              }
            }
          }
        }
      }
    }

    .div-form {
      position: relative;
      display: flex;
      justify-content: flex-start;

      .label-search {
        position: absolute;
        z-index: 1;
        margin-top: 13px;
        margin-left: 15px;
        color: #5f5f5f;
        font-family: "AccordAlternateBold", "serif";
        font-size: 16px;
        text-transform: uppercase;
      }

      .label-search:focus + .items{
        opacity: 1;
      }

      .items{
        position:absolute;
        top: 75px;
        background-color: #fff;
        opacity: 0;
      }
    }

    .div-form select:after {
      content:'\25BC';
      display:inline-block;
      box-sizing:border-box;
      color:#000;
      background-color:#fff;
      border:1px solid #999;
      margin-left:-17px;   /* remove the damn :after space */
      pointer-events:none; /* let the click pass trough */
    }
  }
}

.ref-container .modul, #reference .modul{
  position: fixed;
  top: 80px;
  background-color: rgba(0,0,0,.7);
  backdrop-filter: blur(5px);


  .modul-dialog{
    max-width: 100%;
    width: 800px;
    //height: 600px;
    margin: 60px auto;


    .modul-content{
      height: auto !important;
      background-color: white;
      text-shadow: 0px 0px 20px rgba(0,0,0,.5), 0px 0px 20px rgba(163, 115, 115, 0);

      .modul-body{
        overflow-y: hidden;
        overflow-x: hidden;
        //height: calc(100vh - 300px);
        padding: 0;
        margin: 20px 0;

        .modul-slider{
          .items{
            display: flex;
            flex-direction: row;
            //flex-wrap: wrap;
            margin: 0;

            .item{


              background-repeat:no-repeat; 
              background-size:cover; 
              background-position:center; 
              width: calc(100% - 40px);
              //height: 450px;
              margin: 0 20px 20px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}


#tracol-img-section-1 {
  width: 100%;
  display: flex;
  flex-direction: column;
}

#section-1-locative{
  margin-top: 15px;
}

#section-2-locative{
  padding-left: 40px;
}

#svg-habiter{
  margin-bottom: 15px;
}

.nav-item {
  font-size: 18px;
  font-family: "AccordAlternateLight", "serif";
  margin-right: 1.5vw;
  letter-spacing: 0.6px;
  text-shadow: 0px 0px 10px rgba(0,0,0,.5), 0px 0px 10px rgba(163, 115, 115, 0);
}

.nav-link {
  color: white !important;
  text-transform: uppercase;
  transition: all .3s;

  &:hover {
    color: #fff;
    opacity: .5;
  }

  &:focus {
    color: white;
  }

  &.active {
    color: white;
  }
}

nav.navbar .nav-link {
  text-transform: none;
}

.col-lg-6.pre-section-content{
  padding-right: 156px;
  margin-bottom: 40px;
}

.svg-inline--fa.fa-w-14{
  margin-right: 20px;
  position: relative;
  top: 0px;
  left: 4px;
}

#location-drop-mobile.dropdown .droped .items .item {
  padding: 10px;
  background:#fff;
  margin:0!important;
}

#location-li-mobile {
  height: 200px!important;
  overflow: hidden!important;
  overflow-y: auto!important;
}

#property-type {
  border-radius: 0px;
  border-left: 1px solid;
}
#transaction-type {
  border-radius: 0px;
  border-left: 1px solid;
}

.arrows-slide-row{
  position: relative;

  .arrows-slide{
    position: absolute;
    right: 0;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    align-items: center;
    width: auto;
  }
}

.ref{
  margin: 0 15px 10px 0;
}

.ref-container{
  padding-top: 20px;
}

#references-home{
  margin-top: 50px !important;
  margin: 0 auto;

  .ref-container{
    justify-content: center;
    flex: 0 0 auto;
    width: 100%;
  }

  .div-hover-2{
    //display: none;
  }
}

.filters-biens{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .select-projet{
    width: fit-content;
    margin: 10px;
    padding-right: 15px;
  }
}

#rooms-drop .undroped-header, #area-drop .undroped-header {
  opacity:1!important;
  font-size:1.1em;
}

#rooms-drop.dropdown {
  border-right: 1px solid black!important;
}

#rooms-drop .undroped-subheader {
  margin-top:0!important;
}

#rooms-drop, #area-drop{
  margin: 0 !important;
  padding-left:3px;
  height: 45px !important;
  width: 100%!important;
  border: 1px solid black;

  &.dropdown{
    border-right: none;

    .undroped::after{
      top: 9px;
      right: 6px;
    }

    .droped .dropdown-header {
      text-transform: initial;
      color: rgba(255, 255, 255, 0.6);
      font-weight: initial;
      font-family: "AccordAlternate";
    }
  } 

  .undroped-header{
    text-transform: initial;
    opacity: .4;
    font-weight: initial;
    font-family: "AccordAlternate";
  }
}


/* -------------- Lightbox -----------------*/

.lightbox{
  position: fixed;
  top: 127px;
  background-color: rgba(0,0,0,.7);
  backdrop-filter: blur(5px);
  display: none;
  opacity: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;

  .closer{
    position: absolute;
    top: 56px;
    right: 21vw;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    font-size: 40px;
    color: white;
    background-color: rgba(0, 0, 0, 0.3);
    transition: all .3s;
    cursor: pointer;

    &::before{
      content: '×';
      position: absolute;
      top: -14px;
      left: 8px;
    }

    &:hover{
      opacity: .5;
      transform: rotate(45deg);
    }
  }

  .counter{
    position: absolute;
    top: 56px;
    right: calc(21vw + 200px);
    color:rgba(255, 255, 255, 0.6);
    font-size: 24px;
  }

  .arrows{
    position: absolute;
    top: 200px;
    
    width: 20px;
    height: 20px;
    border-radius: 20px;
    font-size: 60px;
    color: white;
    transition: all .3s;
    cursor: pointer;

    &.left{
      left: 20vw;

      &:hover{
        left: calc(20vw - 5px);
      }
    }

    &.right{
      right: calc(20vw + 35px);

      &:hover{
        right: calc(20vw + 30px);
      }
    }

    &:hover{
      opacity: .5;
    }
  }

  .items{
    width: 50vw;
    max-width: 1000px;
    margin: 100px auto 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    overflow: hidden;

    .item{
      cursor: pointer;

      .img{
        width: 50vw !important;
        height: 100%;
        max-width: 1000px;
        margin: 10px;

        &.horizontal{
          width: 30vw !important;
          //height: 50vh;
          //margin: 0 9vw;
        }
      }
    }
  }
}

/* -------------- Lightbox end -----------------*/

.modul-backdrop{
  display: none;
}

.text-property-projet-detail{
  font-family: "AccordAlternateBold", "serif";
  font-size: 16px;
  text-transform: uppercase;
  border-bottom: none;
}

.project-title-detail {
  font-family: "AccordAlternateBold", "serif";
  font-size: 48px;
  text-transform: uppercase;
}
#svg-projet-detail {
  margin-top: 15px;
}

.text-property-projet-orange {
  font-family: "AccordAlternateMedium", "serif";
  color: #f6a400;
  font-size: 16px;
}

.text-property-detail-white {
  font-family: "AccordAlternateMedium", "serif";
  color: black;
  font-size: 16px;
}

.text-property-arrow > a{
  text-decoration: none;
  font-size: 16px;
  color: #000;
}

.text-property-arrow > a:hover{
  text-decoration: none;
  font-size: 16px;
  color: #f6a400;
}

.text-property-arrow {
  display: flex;
  margin: -8px;
}

.detail-news-font {
  font-family: "AccordAlternate", "serif";
  font-size: 16px;
  color: black;
}
.detail-news-font-h1 {
  font-family: "AccordAlternateBold", "serif";
  font-size: 32px;
  color: black;
}

#interior-div-project-detail {
  margin-bottom: 20px;
}

.header-habiter-title.col-lg-12 {
  margin-top: 7px;
  text-shadow: 0px 0px 10px rgba(0,0,0,.2), 0px 0px 10px rgba(163, 115, 115, 0);
}

.header-home {
  background-repeat:no-repeat;
  background-size: cover;
  background-position: bottom;
  //height: 1082px;
  min-height:100%;
  margin-top: 0 !important;
  position: relative;
  z-index: 0;
}

.d-flex.flex-column.flex-shrink-0.bg-light.black {
  position: fixed;
  z-index: 1111;
  right: 0px;
  top: 0px;

  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  //background-color: #242424 !important;
  background-color: #000 !important;
  height: 180px;
}
.d-flex.flex-column.flex-shrink-0.bg-light.orange {
  position: fixed;
  z-index: 1111;
  right: 0px;
  top: 155px;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  margin-top: 25px;
  background-color: #F6A400 !important;
  height: 240px;
  width: 4rem;
  cursor: pointer;
  transition: all .3s;

  &:hover{
    width: 5.5rem;
  }
}

.text-vertical:active .d-flex.flex-column.flex-shrink-0.bg-light.orange {
  width: 25.5rem !important;
}

.svg-inline--fa.fa-facebook-f.fa-w-10 {
  height: 30px;
  width: 100%;
  color: white;
  margin-top: 20px;
  //left: -6px;
  position: relative;
}

.svg-inline--fa.fa-linkedin.fa-w-14 {
  height: 30px;
  width: 100%;
  color: white;
  margin-top: 20px;
}

.svg-inline--fa{
  &.fa-instagram.fa-w-14, &.fa-linkedin.fa-w-14, &.fa-facebook-f.fa-w-10 {
    height: 30px;
    width: 100%;
    color: white;
    margin-top: 20px;
    cursor: pointer;
    transition: all .3s;

    &:hover{
      opacity: .3;
    }
  }

  &.fa-linkedin.fa-w-14{
    padding-right: 6px;
  }

  &.fa-instagram.fa-w-14{
    padding-right: 6px;
  }
}

.text-vertical {
  writing-mode: vertical-rl;
  padding-top: 65px;
  padding-left: 4px;
  color: white;
  text-decoration: none;
}

.navbar-expand-xl .navbar-collapse.show {
  height:100vh!important;
}

.nav-elements.show ul {
  background:#fff;
}

.phone {
  margin-right: 110px;
  font-size: 18px;
  color: white;
  font-family: "AccordAlternateMedium", "serif";
  //text-shadow: 1px 1px black;
  text-shadow: 0px 0px 10px rgba(0,0,0,.2), 0px 0px 10px rgba(163, 115, 115, 0);
}
.phone > a {
  color: #f6a400;
  text-decoration: none;
  transition: all .3s;
}
.phone > a:hover {
  color: #fff;
  text-decoration: none;
}

#form-search-normal{
  margin-left: calc(16.666666vw - 28px);
}

#home-section{
  margin-top: 100px;
}

.home-section-title{
  font-family: "AccordAlternateBold", "serif";
}

#button-property{
  margin: 0 auto;
}

.separator{
  display: block;
  width: 10px;
  min-width: 10px;
  height: 56px;
  margin: 24px 75px 0 25px;
  background-color: #efefef;

  &.job{
    margin: 24px 25px 0 75px;
  }
}

.fas.fa-phone {
  direction: rtl;
}

.svg-phone {
  margin-right: 10px;
}

// class dynamic js
.extend {
  width: 25rem !important;
  transition: 0.5s;
}

.margin-left {
  margin-left: -340px;
  transition: margin-left 0.5s;

}

.span-newsletter {
  padding-top: 25px !important;
  position: fixed !important;
  top: 185px !important;
  opacity: 1 !important;
  transition: opacity 2s  !important;
  color: white;
}

.form-newsletter {
  display: block !important;
  margin-top: 25px !important;
}

.div-form-newsletter {
  opacity: 1 !important;
  transition: opacity 2s !important;
}

#text-vertical:hover {
  color: white;
  text-decoration: none;
}
#text-vertical:focus {
  color: white;
  text-decoration: none;
}

.form-control.input-email {
  background-color: #E57C01;
  border-color: #f6a400;
  color: white;
  margin-right: 10px;
}
.form-control.input-email:focus {
  background-color: #C46300;
  border-color: #f6a400;
  color: white;
  margin-right: 10px;
}

.form-control.input-email::placeholder {
  color: white;
}

#filter-project-desktop{
  margin: 30px 0 20px;
  display: flex;
}

.header-text {
  color: white;
  font-family: "AccordAlternateBold", "serif";
  font-size: 42px !important;
  text-transform: uppercase;
  text-align: left;
  line-height: 1.1;
  text-shadow: 0px 0px 10px rgba(0,0,0,.6), 0px 0px 10px rgba(163, 115, 115, 0);
}

.flicking-viewport{
  width: 100%;

  .flicking-camera{
    flex-wrap: wrap;
    justify-content: space-between !important;
  
    .number-content {
      font-family: "AccordAlternateMedium", "serif";
      margin-bottom: 60px;
  
      h2{
        font-size: 3vw !important;
        font-family: "AccordAlternateBold", "serif";
        //display:none;
        strong{
          opacity: 0;
          transition: all 1s;
  
          &.count{
            opacity: 1;
          }
        }
  
        .sqmeters{
          font-size: 2.5vw;
        }
      }
  
      p{
        font-size: 1vw !important;
        font-weight: 100;
        margin-bottom: 0;
  
        &:first-child{
          margin-bottom: 20px;
        }
      }
  
      /*&.constr{
        width: 190px;
      }
  
      &.comm{
        width: 190px;
      }
  
      
  
      &.coll{
        width: 190px;
      }*/
  
      &.porte{
        width: 16vw;
      }
    }
  
    .separator{
      margin: 3vw 1vw 0;
    }
  }
}


.div-svg-header {
  display: flex;
  margin-left: calc(16.666666vw - 28px);
}

.last-section-project{
  margin-left: 10px !important;
}

.footer{
  background-color: black !important;

  .footer-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;

    li{
      color: white;
      list-style-type: none;
      font-size: 14px;
    }
  }
}

.border-left {
  border-left: solid 15px #BFBFBF !important;
  height: 40px;
}

.grey {
  color: #5F5F5F !important;
  text-decoration: underline;
  text-align: center;
  margin: 0 5px;

  a{
    color: #5F5F5F !important;

    &:hover{
      opacity: .5;
    }
  }
}

.child {
  height: 65px;
}

.form-select.child {
  height: 75px;
  position: relative;
  padding-top: 33px;
  font-family: "AccordAlternate", "serif";
}




.properties {
  margin-top: 80px;
}

.all {
  width: 113px;
  min-width: 25%;
  height: 90px;
  background-color: #f6a400 !important;
  border-color: #f6a400 !important;
}

.span-all {
  width: 47px;
  height: 21px;
  color: #ffffff;
  font-family: "AccordAlternateBold", "serif";
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  margin: -5px;
  font-size: 18px;
}

.come:not(.reference-page .come) {
  width: 192px;
  height: 69px;
  background-color: #242424 !important;
  border-color: #242424 !important;
  margin-top: 20px;
}

.span-projects {
  width: 86px;
  height: 21px;
  color: #bfbfbf;
  font-family: "AccordAlternateBold", "serif";
  font-size: 18px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: center;
  // Text style for "En cours"
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-transform: uppercase;
}

.projects-link {
  text-align: end;
  width: auto !important;
  margin-left: 20px;
}

.margin-top-30 {
  margin-top: 15px;
  overflow: hidden;
  width: -webkit-fill-available;
}
.border-radius {
  border-radius: 0px !important;
}

.city {
  width: 100%;
  height: 15px;
  color: #707070;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-transform: uppercase;
  padding: 15px 0 10px 0;
  font-family: "AccordAlternateMedium", "serif";
}

.project-name {
  width: 100%;
  height: 46px;
  color: #707070;
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  font-family: "AccordAlternateMedium", "serif";
  line-height: normal;
  text-transform: uppercase;
  padding-left: 0;
}

#link-palyer {
  margin-top: -60px;
  width: 66.66%;
  margin: 0 auto;
}
.property-promotion {
  margin-top: 150px;
}

section.reference {
  background: #F7F7F7;
  margin-top: 100px;
  padding-top:100px;
  margin-bottom: 100px;
  padding-bottom:100px;
}

section.newsletter {
  background: #F6A400;
  padding: 50px 0 50px 0;

  .col-lg-8 {
    align-items: center;
  }

  #span-newsletter {
    color:#fff!important;
  }
}

footer {
  .legal .contact-link {
    margin-right:90px;
  }
  .legal a {
    text-align: initial;
  }
}

select.with-appearance.form-control {
  appearance: auto!important;
}

#formResearchNav {
  position: fixed;
  top:130px;
  z-index:99;
  width:100%;
  height:85px;
  background:#F7F7F7;
}

#properties {
  .img {
    margin:0!important;
  }

  .tag-ref-projet-2 {
    top: 0px;
    right: 16px;
  }
}

.properties-slide {
  padding:0 15px 0 0;

  .slick-arrow {
    visibility: initial;
  }

  .arrow-right {
    transform: rotate(180deg);
    right:15px;
    bottom:0;
  }

  .arrow-left {
    left:0;
    bottom:0;
  }
}

#propertiesOther .properties-price {
  color:#F6A400;
}

#properties, #propertiesOther {
  .items {
    margin-top:20px;
    color:#F6A400;

    .surface-logo {
      margin-right:10px;
    }
  }

  .properties-price {
    color:#F6A400;
    font-size:1.6em;
  }

  .city {
    padding: 0px 0 10px 0;
  }

  .div-img-hover-2 {
    margin-bottom:25px;
  }
}

#propertiesOther {
  .arrow-right {
    transform: rotate(180deg);
    right: 24px;
    bottom: 9px;
  }
  .arrow-left {
    left: 9px;
    bottom: 9px;
  }

  .infos-list, .city, .project-name {
    padding-left:8px;
  }
}


.properties-slide .slick-track {
  height:265px;
}


.square-black {
  width:35px;
  height:35px;
  background:#242424;
  z-index:9;
}

.header-habiter.slider-not-home {
  margin-top:195px!important;
  width: 71.666vw;
  margin: auto;
  height: 70vh!important;
}

.header-habiter .d-block {
  background-size:cover!important;
  height:100%;
  width:100%;
  min-height:70vh;
}

section.items-property-single {
  .item-single {
    width:15.5%;
    height: 105px;
    max-height: 36px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }

  .item-single.availability {
    color:#F6A400;
  }

  img {
    width:45px;
    max-height:34px;
  }
}

section.desc-property {
  .project-title {
    border-right: 6px solid #EFEFEF;
  }

  .img:not(.img.active) {
    height:75px;
    object-fit:cover;
  }

  .price {
    font-size:1.8em;
    font-weight: 700;
  }

  .contact-property {
    background: #F7E7CB;
    color:#242424;

    p {
      font-size:1em;
    }
  }

  .contacinfos {
    background: #F6A400;
    color:#fff;
  }
}

.similar-properties {
  padding-top: 50px;
  margin-top: 80px;
}

.teams {
  margin-top:210px;
  padding-bottom:60px;

  .project-title {
    display: flex;
    justify-content:space-between;
  }

  .separe {
    width: 10px;
    height: 56px;
    background-color: #efefef;
  }

  #section-1-tracol {
    margin-top:0!important;
  }

  .img-team {
    height:250px;
  }

  .list-categories {
    padding-top:60px;
    padding-bottom:60px;
  }

  .infos-member {
    margin:10px 0;
  }

  .infos-member span {
    font-size: 1.1em;
    text-transform: uppercase;
  }
  .infos-member span:first-child {
    font-family: "AccordAlternateMedium", "serif";
  }
  .infos-member span:last-child {
    font-family: "AccordAlternateBold", "serif";
  }
}
.project-desktop .tag-ref-projet-2 {
  right:22px;
}

.project.filter {
  .input-filter {
    margin-right:25px;
  }

  input:where([type="checkbox"], [type="radio"]){
    -webkit-appearance : none;
    appearance: none;
    width: 22px;
    height: 22px;
    margin: calc(0.75em - 11px) 0.25rem 0 0;
    border-radius:50%;
    vertical-align: top;
    border: 2px solid #DEDEDE;
    transition:0.4s;
  }
  input:where([type="checkbox"], [type="radio"]):where(:active:not(:disabled), :focus), input.all {
    background: #E9A73A no-repeat center center;
    min-width: initial!important;
  }
}

#projects {
  .city, .project-name {
    margin-left:9px;
  }
}

.project-detail-header {
  .nb-projects {
    background:#F6A400;
    bottom:0;
    right:0;
    padding:20px 25px;
    color:#fff;
    text-transform: uppercase;
    font-weight: bolder;
  }

  .tracol-title, .image-sider {
    margin-top:0;
  }
}
.project-detail-header .nb-projects span{
  font-size:1.4em;
}
.project-detail.project-page {
  margin-top:90px;

  .tracol-title, .image-sider {
    margin-top:0;
  }
}
.item.surface {
  font-family: "AccordAlternateMedium", "serif";
  display: flex;
}
#interior-div-project-detail {
  .text-property-projet-detail {
    color:#F6A400;
  }
}

.header-tracol.header-contact {
  margin-top:200px!important;
}

.reference-page {
  margin-top:300px;

  #button-reference-page {
    margin-bottom:20px;
  }

  .date-ref {
    display: block;
    width:auto;
    margin-bottom:0;
    position: initial;
    padding-top: 8px;
    padding-right: 8px;
  }
  .city {
    width:auto;
  }
}

.reference-list-single {
  .div-hover-2 {
    width:360px;
  }
  .city, .project-name, .date-ref {
    color:#fff!important;
    padding-left:15px;
  }
  .project-arrow-2 {
    width:90%!important;
    display: flex;
    justify-content: space-between;
  }
  .input-filter {
    margin-right:25px;
  }
  input {
    min-width: auto!important;
    background-color: transparent;
  }
  input:where([type="radio"], [type="radio"]){
    -webkit-appearance : none;
    appearance: none;
    width: 22px;
    height: 22px;
    margin: calc(0.75em - 11px) 0.25rem 0 0;
    border-radius:50%;
    vertical-align: top;
    border: 2px solid #DEDEDE;
    transition:0.4s;
    background-color: transparent;
  }
  input:where([type="checkbox"], [type="radio"]):where(:active:not(:disabled), :focus) {
    background: #E9A73A no-repeat center center;
  }
}

#submit-search .search-button {
  height: 65px;
}

// JULIEN

.home-sale-img{
  display: block;
  width: 100%;
  height: 600px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 16px;
  background-position: center;
}

.project-title {
  color: #242424;
  font-family: "AccordAlternateBold", "serif";
  font-size: 40px;
  font-weight: 700;
  text-align: left;
  font-style: normal;
  text-transform: uppercase;
  //white-space: nowrap;
  margin: 10px 60px 30px 0;

  &.contact{
    font-size: 26px;
  }
}

#div-form-contact #login_user{
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 10px;
  transition:  all .3s;

  &:hover{
    opacity: .5;
  }
}
#div-form-contact .col-md-1 {
  display: flex;
  justify-content:flex-end;
  align-items: end;
}
#div-form-contact .submit-contact-button {
  padding-bottom:0!important;
}

#login_user:not(#div-form-contact #login_user){
  width: 200px;
  padding: 15px;
  border: none;
  border-radius: 10px;
  transition:  all .3s;

  &:hover{
    opacity: .5;
  }
}
.col-lg-4.property-content > p {
  font-family: "AccordAlternate", "serif";
}
.project-content {
  margin-top: -10px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 0.8em;
  opacity: 0;
}

.project-content-p {
  color: #242424;
  font-family: "AccordAlternate", "serif";
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
}

.div-hover{
  position: absolute;
  bottom: 0px;
  background-color: #242424;
  color: white;
  width: 291px;
  height: 70px;

  /*&:hover {
    height: 330px !important;
    transition: all .3s;

    .project-content {
      visibility: visible !important;
      opacity: 1;
      transition: opacity 1s !important;
      overflow: hidden;
      transition: all .3s;
    }
  }*/
}

.project-svg-arrow {
  margin-left: 85%;
  background-color: orange;
  padding: 3px;
  color: white;
  border: 5px solid orange;
  font-size: 30px;
}

.property-name {
  color: #242424;
  font-family: "AccordAlternateBold", "serif";
  font-size: 48px;
  font-weight: 700;
  text-align: left;
  font-style: normal;
  text-transform: uppercase;
  //white-space: nowrap;
  margin: 10px 0 50px;
}

#jobs-content{
  //margin-left: 20px;
}

#jobs-content > p  {
  font-family: "AccordAlternate", "serif";
}
#jobs-content > h2  {
  font-family: "AccordAlternate", "serif";
}

#jobs-delete > p {
  font-family: "AccordAlternate", "serif";
}
#jobs-delete > h2 {
  font-family: "AccordAlternate", "serif";
}
#jobs-delete {
  font-family: "AccordAlternate", "serif";
}

.immobiler {
  font-family: "AccordAlternate", "serif";
}
.immobiler > h2 h1 {
  font-family: "AccordAlternate", "serif";
}

#filter-desktop > button {
  //padding-right: 30px;
}
#filter-project-desktop > button {
  padding-right: 5px;
  margin: 0 10px;
}

.property-span-arrow {
  margin-top: 5px;
  color: #bfbfbf;
  font-family: "AccordAlternateBold", "serif";
  font-style: normal;
  text-transform: uppercase;
  text-decoration: none;
  //display: flex;
  align-items: center;
  justify-content: flex-start;
  float: right;
  height:40px;
  display: flex;
  //margin-left: -39px;
  //width: 110% !important;

  .svg-inline--fa.fa-w-14 {
    top: 0;
  }

  &:hover {
    color: #f6a400;

    .property-arrow{
      background-color: #f6a400 !important;
      color: white !important;
      border-color:  #f6a400 !important;
    }
  }

}

.property-arrow {
  font-size: 24px;
  border: 2px solid #bfbfbf;
  color: #BFBFBF;
  margin-left: 10px;
  width: 36px !important;
  padding: 5px;
  height: 36px;
  color: #bfbfbf;

  &:hover {
    background-color: #f6a400;
    color: white;
    border-color:  #f6a400;
  }
}


.img-100 {
  width: 100%;
  padding: 8px;
}



.img-tmp {
  width: 400px;
  height: 400px;
  //padding: 8px;
  /*margin: 5px;*/

  background-repeat: no-repeat;
  background-size: cover;
}

.navbar-expand-xl .navbar-nav .nav-link, nav.navbar.navbar-expand-xl #center-nav .phone-contact .phone {
  color:#000000!important;
}


nav.navbar.navbar-expand-xl {
  height:130px;
  background: #fff;
}

#references-home {
  .img-tmp {
    width: 100%;
  }

  .ref-list {
    /*padding:20px;*/
    text-decoration:none!important;
  }
  .padd-to-left {
    padding-left:15px;
  }

  .padd-to-right {
    padding-right:15px;
  }
  .tag-ref {
    top:0;
    right:0;
  }
  a {
    text-decoration: none;
  }
}

.height-100 {
  height: 100%;
}

.height-523 {
  height:523px;
}

.height-257 {
  height: 50vw;
  padding: 0;
  margin-top: 8px;
}

.margin-home-ref {
  margin-left: 8px;
}

.swiper {
  position:relative;
  width: 100%;
  height: 620px;
  overflow: hidden;
}

.swiper-wrapper{
  position:absolute;
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  top:0;
  left:0;
}

.swiper-wrapper > div {
  height:100%;
}

.project-svg-arrow-2 {
  margin-left: 4%;
  background-color: orange;
  padding: 3px;
  color: white;
  border: 5px solid orange;
  //font-size: 30px;
  width: 36px !important;
  height: 36px;
}

.div-img-hover-2 {
  position: relative;
  overflow: hidden;

  &.news{
    //width: calc(100% - 6px);

    .tag-ref-projet-2{
      right: 0;
    }
  }
}

/*.div-img-hover-2:hover .div-hover-2, .div-img-hover-2:focus .div-hover-2, .div-img-hover-2:active .div-hover-2, .div-img-hover-2:active .div-hover-2    {

  //height: 350px !important;
  //transition: height .3s;
  overflow: hidden;
}
.div-img-hover-2:hover .div-hover-2 .project-content, .div-img-hover-2:focus .div-hover-2 .project-content, .div-img-hover-2:active .div-hover-2 .project-content
{
  visibility: visible !important;
  opacity: 1;
  //transition: opacity .3s !important;
}*/

.project-arrow-2 {
  position: absolute;
  height: 40px;
  bottom: 20px;
  left: 20px;
  opacity: 0;
  display: flex;
  justify-content: end;
  margin-right: 30px;
  text-decoration: none;
  align-items: center;
}

.reference-page .project-arrow-2 {
  width:90%;
  display: flex;
  justify-content: space-between;
}

.galerry-ref {
  color: #f6a400;
  font-family: "AccordAlternateMedium", "serif";
}

.date-ref {
  display: none;
  width: 82px;
  height: 20px;
  color: #bfbfbf;
  font-family: "AccordAlternate", "serif";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  position: absolute;
  top: 6px;
  left: 196px;
}

.tag-ref {
  position: absolute;
  top: 25px;
  right: 15px;
  //width: 112px;
  //height: 34px;
  padding: 5px 20px;
  background-color: #f6a400;
  text-align: center;
  opacity: 1;
}

.text-tag-ref {
  width: 94px;
  height: 19px;
  color: #ffffff;
  font-family: "AccordAlternate", "serif";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
}

/*.div-img-hover-2:hover .tag-ref
{
  opacity: 0;
  transition: opacity 1s !important;
}*/

.tracol-immo-name {
  width: auto;
  height: 57px;
  color: #f6a400;
  font-family: "AccordAlternateBold", "serif";
  font-size: 48px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
  margin-left: 0;
  //margin-top: 10px;
}

.tracol-title {
  margin-top: 36px;

  &.section2{
    padding-left: 40px;
  }

  &.section3{
    padding-right: 40px;
  }
}

.tracol-title > h3 {
  padding-left: 25px;
}

.tracol-img {
  margin-top: 105px;
}

.tracol-title > h2 {
  text-transform: uppercase;
  font-family: "AccordAlternateBold", "serif";
  font-size: 24px;
  padding: 5px 0;
  margin-top: 10px;
}

.tracol-title > svg {
  margin-left: 2px;
}

.tracol-content {
  color: #242424;
  font-family: "AccordAlternate", "serif";
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  line-height: 24px;
  text-align: left;
  margin-top: 45px !important;
}

#section-1-tracol{
  margin-top: 74px;
  padding-right: 40px;
}

#section2-tracol{
  margin-top: 26px;
  padding-left: 40px;
  max-width: 500px;
}



//accordion
#accordionExample {
  margin-top: 76px;
  position: relative;
}

.accordion-header {
  margin-top: 10px;

  .accordion-button {
    font-family: "AccordAlternateBold", "serif" !important;

    &:hover{
      color: #F6A400 !important;
    }

    &::before{
      position: absolute;
      display: block;
      top: 12px;
      left: -5px;
      content: url("/assets/images/arrow-orange.svg");
      transition: all .3s;
      transform: rotate(-90deg);

    }
  }
}

.accordion-button:not(.collapsed) {
  color: #f6a400 !important;
  background: rgba(0,0,0,0) !important;
  box-shadow: none !important;
}

.accordion-item {
  border: none !important;
  padding-right: 40px;
}

.accordion-button::after {
  content: none !important;
}

.accordion-button.collapsed {
  color: #f6a400 !important;
  font-family: "AccordAlternate", "serif" !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  text-align: left !important;
  text-transform: uppercase !important;
  background: rgba(0,0,0,0) !important;
  transition: all .3s;

  &::before{
    transform: rotate(0deg);

  }
}

.accordion-button {
  color: #f6a400 !important;
  font-family: "AccordAlternate", "serif" !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  text-align: left !important;
  text-transform: uppercase !important;
}

.accordion-body {
  color: #242424;
  font-family: "AccordAlternate", "serif";
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  text-align: left;
}

#svg-immo{
  position: absolute;
  z-index: 2;
  left: 22px;
}


.under0 {
  top: 0px;
  transition: top 1s;
}
.under1 {
  top: 70px;
  transition: top 1s;
}
.under2 {
  top: 180px;
  transition: top 1s;
}
.under3 {
  top: 290px;
  transition: top 1s;
}


#habiter-content-id {
  margin-top: 87px;
}

#link-none-a {
  //margin-top: -40px;
}

//page tracol
.header-tracol {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 1000px;
  min-height: 100%;
}

.header-habiter {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 500px;
  min-height: 100%;
}

.header-habiter-title {
  color: #ffffff;
  font-family: "AccordAlternateBold", "serif";
  font-size: 42px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  text-transform: uppercase;
  //text-shadow: 1px 1px black;
  text-shadow: 0px 0px 10px rgba(0,0,0,.4), 0px 0px 10px rgba(163, 115, 115, 0);
}

.header-habiter-div {
  margin-top: 250px;
}
.header-habiter-div2{
    margin-top: 145px;
}
.header-content {
  color: #ffffff;
  font-family: "AccordAlternateMedium", "serif";
  font-size: 19px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  line-height: 24px;
  text-align: left;
  text-shadow: 0px 0px 10px rgba(0,0,0,.4), 0px 0px 10px rgba(163, 115, 115, 0);
}

.habiter-content > span {
  color: #f6a400 !important;
}
.pre-section {
  display: flex;
  margin-top: 30px;
  justify-content: start;
  //align-items: center;
}

.pre-section-content {
  display: flex;
  flex-direction: column;
  color: #242424;
  font-family: "AccordAlternate", "serif";
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  margin-top: 19px;
}

section.project {
  .separator {
    margin: 20px 75px 20px 25px;
  }
  .pre-section-content {
    margin-top: 0;
  }
}

section.reference {
  .separator {
    margin: 14px 75px 0 25px;
  }
}

.select-projet {
  color: #bfbfbf;
  font-family: "AccordAlternateBold", "serif";  font-size: 16px;
  text-align: right;
  text-transform: uppercase;
  padding-right: 13px;
  background-color: transparent;
  border-color: transparent;
  transition: all .3s;
}

.tag-ref-projet {
  position: absolute;
  top: 1px;
  right: 1px;
  width: 113px;
  height: 30px;
  background-color: #f6a400;
  text-align: center;
}

.tag-ref-projet-2 {
  position: absolute;
  top: 9px;
  right: 7px;
  width: 113px;
  height: 30px;
  background-color: #f6a400;
  text-align: center;
  padding-top: 4px;
  padding-top: 4px;
  text-shadow: none;
}

.similar-properties{
  .tag-ref-projet-2 {
    right: 0;
  }
}

.tag-ref-projet-3 {
  position: absolute;
  top: 0px;
  width: 113px;
  height: 34px;
  background-color: #f6a400;
  text-align: center;
  right: 16px;
  padding-top: 4px;
}

.width-100 {
  width: 100%;
}

.height-380 {
  height: auto;
}
.height-600 {
  height: auto;
}

.more-projects {
  color: #dedede;
  font-family: "AccordAlternateMedium", "serif";
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 22px;
  text-align: left;
  text-transform: uppercase;
  margin-top: 50px;
  margin-bottom: 5px;

  display: none;
}

.more-projects:hover {
  color: #f6a400;
}

.job-vacancy {
  position: absolute;
  right: 103px;
  bottom: 0px;
  color: white;
  background-color: #f6a400;
  //height: 64px;
  width: 35vw;
  text-align: left;
  padding: 15px;
}

.jobs {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.jobs > h3 {
  color: #ffffff;
  font-family: "AccordAlternateMedium", "serif";
  text-transform: uppercase;
  font-size: 30px;
}
.jobs > a {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  margin-top: -15px;
}

.jobs > a:hover {
  color: white;
  text-decoration: none;
}

.job-titles {
  width: 310px;
  height: 150px;
  background-color: #f6a400;
}

.white-color-job-h1 {
  color: white;
}

.job-titles-h1 {
  font-family: "AccordAlternateMedium" ,"serif";
  font-size: 21px;
  text-transform: uppercase;
  padding-top: 15px;
  text-align: left;
  padding-left: 20px;
  background-color: #f6a400;
  border: none;
}

.job-titles-h1.job-active {
  font-family: "AccordAlternateMedium" ,"serif";
  font-size: 21px;
  text-transform: uppercase;
  padding-top: 15px;
  text-align: left;
  padding-left: 20px;
  background-color: #f6a400;
  border: none;
  color: white;
}

.job-speculative-application {
  //height: 316px;
  background-color: #bfbfbf;
  margin-top: 50px;

  > div{
    padding-bottom: 40px;
  }
}

.job-speculative-application-h3 {
  color: #ffffff;
  font-family: "AccordAlternateMedium" ,"serif";
  font-size: 32px;
  text-transform: uppercase;
  text-align: left;
  padding-left: 20px;
  padding-top: 15px;
}

.job-speculative-application-text {
  color: #ffffff;
  font-family: "AccordAlternate" ,"serif";
  font-size: 16px;
  text-align: left;
  padding: 20px;
}

.job-span-arrow {
  color: white;
  text-decoration: none;
  padding-left: 20px;
}
.job-span-arrow:hover {
  font-family: "AccordAlternateMedium" ,"serif";
  color: white;
  text-decoration: none;
  padding-left: 20px;
}
.svg-inline--fa.fa-arrow-right.fa-w-14.job-property-arrow {
  font-size: 33px;
  border: 2px solid white;
  color: white;
  margin-left: 10px;
  padding: 5px;
  height: 36px;
  margin-bottom: -7px;
}

.form-material.floating > input {
  margin-bottom: 25px;
  background-color: #f5f5f5;
  border: none;
  height: 35px;
}

.file-select {
  background-color: #bfbfbf !important;
}

.form-material.floating > select {
  margin-bottom: 25px;
}

#contact_form_object {
  background-color: #5f5f5f;
  color: white;
}

.selectdiv {
  position: relative;
}

.selectdiv:after {
  content: "\f063";
  font-family: 'Font Awesome 5 Free';
  color: white;
  right: 0px;
  padding: 9px 0px 0px 0px;
  border-left: none;
  position: absolute;
  pointer-events: none;
  background-color: black;
  height: 36px;
  font-weight: 900;
  width:30px;
  display: flex;
  justify-content: center;
}

/* IE11 hide native button (thanks Matt!) */
select::-ms-expand {
  display: none;
}

.selectdiv select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  float: right;
  color: #333;
  background-color: #ffffff;
  background-image: none;
  border: none;
  -ms-word-break: normal;
  word-break: normal;
}

.properties-city {
  color: #ffffff;
  font-family: "AccordAlternate", "serif";
  font-size: 20px;
  text-align: left;
  text-transform: uppercase;
  padding: 15px 0 0 15px;
}

.properties-area {
  color: #ffffff;
  font-family: "AccordAlternate", "serif";
  font-size: 20px;
  text-align: right;
  text-transform: uppercase;
  margin: 0;
}
.properties-reference {
  color: #ffffff;
  font-family: "AccordAlternateBold", "serif";
  font-size: 28px;
  text-transform: uppercase;
  text-align: left;
  padding-left: 15px;
}
.properties-price {
  color: #ffffff;
  font-family: "AccordAlternateBold", "serif";
  font-size: 32px;
  text-align: center;
  text-transform: uppercase;
  padding: 15px;
  white-space: nowrap;
}
.properties-link {
  color: #ffffff;
  font-family: "AccordAlternateMedium", "serif";
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 13px;
}
.properties-link:hover {
  color: #ffffff;
  font-family: "AccordAlternateMedium", "serif";
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  text-shadow: 1px 1px black;
}

.container-properties-header {
  background-color: #242424;
  min-height: 62px;
  display: flex;
  align-items: first baseline;
  padding: 0 18px 0 8px;
  color:rgba(255, 255, 255, .5);

  &.project{
    padding-left: 40px;
  }

  .project-detail-city{
    padding-top: 10px;
  }
}
.sub-container-properties-header {
  background-color: #242424;
  min-height: 62px;
  padding: 0 8px;
}

#slideNextHeader {
  color: white;
  border: 1px solid;
  padding: 5px;
  cursor: pointer;
  transition: all .3s;

  &:hover{
    color: #f6a400;
  }
}

#slidePreviousHeader {
  color: white;
  border: 1px solid;
  padding: 5px;
  cursor: pointer;
  transition: all .3s;

  &:hover{
    color: #f6a400;
  }
}

.last-figure {
  color: white;
  font-size: 20px;
  font-family: "AccordAlternateMedium", "serif";
  margin-right: 5px;
}
.current-figure {
  color: white;
  font-size: 20px;
  font-family: "AccordAlternateMedium", "serif";
  margin-left: 5px;
}

.bar-figure {
  color: white;
  font-size: 20px;
  font-family: "AccordAlternateMedium", "serif";
}

.properties-reference-detail {
  color: #bfbfbf;
  font-family: "AccordAlternate", "serif";
  font-size: 20px;
  white-space: nowrap;
  margin: 0;
}
.arrows-slides {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
  position: absolute;
  bottom: 130px;
}


.infos-person {
  position: absolute;
  right: 60px;
  bottom: 0px;
  z-index: 5;
  box-shadow: 0px 0px 20px rgba(0,0,0,.5), 0px 0px 20px rgba(163, 115, 115, 0);
}
.more-informations {
  width: 265px;
  height: 106px;
  background-color: #5f5f5f;
}

.p-info-property {
  color: #ffffff;
  font-family: "AccordAlternate", "serif";
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  padding: 5px 12px 0px 12px;}

.p-first-property {
  color: #ffffff;
  font-family: "AccordAlternateMedium", "serif";
  font-size: 22px;
  text-align: left;
  padding: 0px 12px 0px 12px;
}

.p-mail-property {
  width: 56px;
  height: 56px;
  background-color: #f6a400;
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-phone-property {
  width: 210px;
  height: 56px;
  background-color: #434343;
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-phone-property a {
  text-decoration: none;
  color: white;
}
.p-phone-property a:hover {
  text-decoration: none;
  color: white;
}

.similar-properties {
  background-color: #f5f5f5;
}

.properties-discover {
  display:flex;
  align-items: center;
  justify-content: flex-end;
}

.spe0cial {
  text-align: right;
  background-color: #f6a400;
  padding: 20px;
  padding-right: 60px;
  height: 318px;
}
.spe1cial {
  text-align: left;
  background-color: #5f5f5f;
  padding: 20px;
  padding-left: 60px;
  height: 318px;
}

.h2-locative-0 > #SVGlocative {
  margin-right: 200px;
}
.h2-locative-0 {
  color: #ffffff;
  font-family: "AccordAlternate", "serif";
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
}

.h2-locative-1 {
  color: #ffffff;
  font-family: "AccordAlternate", "serif";
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
}

.content-locative {
  color: #ffffff;
  font-family: "AccordAlternate", "serif";
  font-size: 16px;
  font-weight: 300;
}




.selectArrow {
  position: relative;
  width: 100%;
}

.selectArrow:after {
  content: "\f078";
  font-family: 'Font Awesome 5 Free';
  color: #f6a400;
  right: 18px;
  padding: 32px 0px 0px 0px;
  border-left: none;
  position: absolute;
  pointer-events: none;
  height: 35px;
  font-weight: 900;
}

.selectArrow select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  float: right;
  color: #dedede;
  font-size: 16px;
  font-family: "AccordAlternate", "serif";
  background-color: #ffffff;
  background-image: none;
  border: none;
  -ms-word-break: normal;
  word-break: normal;
}

footer mt-auto py-3 bg-light {
  height: 161px;
}

.habiter-title {
  text-transform: uppercase;
  font-family: "AccordAlternateBold", "serif";
  font-size: 40px;
}

.habiter-img {
  margin-top: 80px;
}

.habiter-content {
  padding-right: 35px;
}

.col-lg-6.tracol-title > #tracolSVGID {
  margin-top: 35px;
}

.col-lg-6.habiter-content {
  font-family: "AccordAlternate", "serif";
  margin-top: 52px;
}

.col-lg-12.properties-discover {
  margin-bottom: 50px;
}

.col-lg-6.tracol-title > p {
  padding-right: 10px;
  font-family: "AccordAlternate", "serif";
  padding-left: 0;
  margin-top: -15px;
}

.actu-link {
  font-family: "AccordAlternateBold", "serif";
  text-decoration: none;
  color: #f6a400;
  text-transform: uppercase;
}

.actu-link:hover {
  font-family: "AccordAlternateBold", "serif";
  text-decoration: none;
  color: #f6a400;
  text-transform: uppercase;
}

.date {
  font-family: "AccordAlternate", "serif";
  font-size: 24px;
  color: #ffffff;
  margin-left: 15px;
}
#contact_form_message {
  height: 215px;
}

.submit-contact-button {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 23px;
  margin-left: 5px;
  padding: 20px 0;
}
.apply-job {
  display: flex;
}

.job-titles-h1 {
  color: #c48300;
  font-family: "AccordAlternateMedium", "serif";
  font-size: 18px;
}



.job1-span-arrow {
  margin-top: 5px;
  color: white;
  font-family: "AccordAlternate", "serif";
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.job1-span-arrow:hover {
  color: #f6a400;
}
.job1-span-arrow:hover {
  color: #f6a400;
}
.job1-span-arrow:hover .job1-property-arrow{
  background-color: #f6a400 !important;
  color: white !important;
  border-color:  #f6a400 !important;
}


.job1-property-arrow {
  font-size: 33px;
  border: 2px solid white;
  color: white;
  margin-left: 10px;
  width: 40px;
  padding: 5px;
  height: 36px;
  margin-right: 41%;
}

.job1-property-arrow:hover {
  background-color: #f6a400;
  color: white;
  border-color:  #f6a400;
}

.job2-span-arrow {
  margin-top: 5px;
  color: white;
  font-family: "AccordAlternate", "serif";
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.job2-span-arrow:hover {
  color: #5f5f5f;
}
.job2-span-arrow:hover {
  color: #5f5f5f;
}
.job2-span-arrow:hover .job2-property-arrow{
  background-color: #5f5f5f !important;
  color: white !important;
  border-color:  #5f5f5f !important;
}


.job2-property-arrow {
  font-size: 33px;
  border: 2px solid white;
  color: white;
  margin-left: 10px;
  width: 40px;
  padding: 5px;
  height: 36px;
  margin-right: 41%;
}

.job2-property-arrow:hover {
  background-color: #5f5f5f;
  color: white;
  border-color:  #5f5f5f;
}

.div-newsletter-move {
  width: 5.5rem;
}

.project .col-lg-8.mx-auto.div-svg-header {
  margin-top:40px;
}

.sub-search-div {
  position: absolute;
  display: flex;
  bottom: -66px;
  align-items: center;
  margin-top: -11px;
  background-color: #434343;
  height: 60px;
  width: 100%;
  justify-content: space-evenly;
  border-radius: 0 0 15px 15px;
  font-size:0.8em;
}

#sub-search-div {
  input {
    width:100%;
  }
  #rooms-drop, #area-drop {
    flex: 0 0 auto;
    width: 25%!important;
  }
}

.form-select.sub {
  height: 30px;
  position: relative;
  padding-top: 2px;
}



input#area-minimumm:-moz-placeholder{
  color: white !important;
}

input#budget:-ms-input-placeholder{
  color: white !important;
}


selectSub {
  position: relative;

}

.selectSub:after {
  content: "\f078";
  font-family: 'Font Awesome 5 Free';
  color: #f6a400;
  padding: 6px 0px 0px 0px;
  border-left: none;
  position: absolute;
  pointer-events: none;
  background-color: #5f5f5f;
  margin-left: 270px;
  height: 30px;
  font-weight: 900;
}

.selectSub select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  float: right;
  color: #b4b4b4;
  background-color: #5f5f5f;
  background-image: none;
  border: none;
  -ms-word-break: normal;
  word-break: normal;
  border-radius: 0px;
}
.div-appear {
  display: flex !important;
}
.nav-link > svg:hover {
  transform: rotate(360deg);
  transition: transform .7s ease-in-out;
}

.select-projet > .svg-inline--fa.fa-times.fa-w-11 {
  transition: all .3s;
}

.select-projet:hover > .svg-inline--fa.fa-times.fa-w-11 {
  transform: rotate(135deg);
  color: #f6a400;
}
.select-projet:hover {
  color: #5f5f5f;
}

.property-comment {
  max-width: 1068px;
  color: #242424;
  font-family: "AccordAlternate", "serif";
  font-size: 16px;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  padding-left: 7px;
  margin-top: 15px;
}

.text-property {
  color: #5f5f5f;
  font-family: "AccordAlternate", "serif";
  font-size: 18px;
}

.text-property-infos {
  color: #c48300;
  font-family: "AccordAlternateBold", "serif";
  font-size: 18px;
}

.display-none {
  display: none;
}
.display-flex{
  display: flex;
  padding-left: 7px;
  margin-top: 30px;
}


.select-projet-active {
  color: black;
}
.select-projet-active > svg {
  color: #c48300;
}

#location::placeholder {
  color: #dedede;
  font-size: 16px;
  margin-left: 15px !important;
}
#location:-ms-input-placeholder {
  color: #dedede;
  font-size: 16px;
  margin-left: 15px !important;
}
#location ::-ms-input-placeholder {
  color: #dedede;
  font-size: 16px;
  margin-left: 15px !important;
}

.dropdown-menu {
  min-width: 21rem !important;
  border-top: none !important;
}

.navbar-toggler {
  position: absolute;
  top:20px;
  left:20px;
}

.form-search-filter {
  #submit-search {
    margin-left:-20px;
  }
}

.header-habiter.vendre {
  margin-top: 190px!important;
}

section.video-presentation iframe {
  height:710px;
}

#home-sale .property-name {
  font-size:40px;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  background-color: #F6A400;
}

a.carousel-control-prev, a.carousel-control-next {
  opacity:0.8!important;
}

@media only screen and (max-width: 1970px) {
  #home-sale .property-name {
    margin-top: -28px!important;
  }
}

@media only screen and (max-width: 1850px) {
  footer .legal .contact-link {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 1750px) {
  footer #footer-menu .legal {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 1500px) {
  section.video-presentation iframe {
    height:550px;
  }
  #home-sale .property-name {
    margin-top: 13px !important;
    margin-bottom:30px;
  }
  .header-habiter.vendre {
    height: 500px!important;
    margin-top: 100px!important;
  }
  .header-tracol.header-contact {
    margin-top:150px!important;
  }
}

@media only screen and (max-width: 1024px) {
  .tracol-logo {
    margin:auto!important;
  }
}

@media only screen and (max-width: 768px) {
  section.video-presentation iframe {
    height:400px;
  }
}

@media only screen and (max-width: 576px) {
  #area-drop {
    padding-left:0!important;
  }
  form .container-form-mobile {
    width:100%!important;
  }
  section.project .project-title-detail {
    font-size:20px;
  }
  .div-svg-header {
    padding-left:15px;
  }
  #home-sale .property-name {
    font-size:20px;
  }
  .see-video-bottom {
    margin-bottom:25px;
  }
  section.video-presentation iframe {
    height:350px;
  }

  section.newsletter {
    padding: 20px 0 35px 0;
  }

  .project-detail-header .nb-projects {
    width:100%;
  }
  .project-detail-header .nb-projects div:nth-child(2) {
    display: flex;
    align-items: center;
  }
  section.numbers {
    margin-bottom:0!important;
  }
  .home-sale-img {
    margin-top:30px;
  }
  #carouselExampleControls {
    height:100%;
    .carousel-inner {
      height:100%;
    }
    .carousel-item.active {
      height:100%!important;

      .d-block {
        height:100%;
      }
    }
  }

  .header-habiter .d-block {
    min-height: initial!important;
  }

  section.items-property-single.pb-5 {
    padding-bottom:0!important;
  }

  .items-property-single > .justify-content-center {
    justify-content: start!important;
    flex-direction: column;
    align-items:center;

    div:not(div.item-single) {
      display: none;
    }

    .item-single {
      margin-bottom:0;
      width:100%;
    }
    .item-single.availability {
      margin-top:0;
    }
  }
}

.pre-section {
  align-items: center;

  .separator {
    margin: 0 75px 20px 25px;
  }
}

.container-form-search {
  #sub-search-div {
    .undroped {
      margin-top:0;
      margin-left:10px;

      .undroped-subheader {
        margin-bottom:0;
      }
    }

    input#budget {
      color: #F6A400;
      padding-bottom:5px;
    }
  }
}



// ----- MEDIA QUERIES ------------------------//

@media only screen and (min-width: 1400px) {
  #home-sale .property-name {
    margin-top:-80px;
  }
}

@media only screen and (max-width: 1498px) {

  .form-search-filter {
    width:90%!important;

    .container-form-search {
      width:100%!important;
    }
  }

  #formResearchNav {
    top: 66px!important;
  }

  header{
    margin-top: 0 !important;
  }

  .navbar-nav{
    .nav-item{
      margin: 1.8vw 0;
    }
  }

  .flicking-viewport{
    margin-top: 40px;
    .flicking-camera .number-content p {
      font-size: 14px !important;
    }
  } 

  .separator{
    display: none;
  }

  .col-lg-6.pre-section-content{
    padding-right: initial;
  }

  .home-sale-img{
    margin-right: 0;
  }

  .lightbox{
    top: 80px;
    
    .items{
      width: 80vw;

      .item .img{
        width: 80vw !important;
        margin: 0;
      }
    }

    .counter{
      display: none;
    }

    .closer{
      display: none;
    }

    .arrows{
      top: 27px;
    }
  }

  .modul{

    #search-detail-mobile{
      margin-top: 20px;
    }

    .dropdown{
      &::after{
        display: none;
      }
    }

    .sub-search-div{
      margin-top: 20px;
    }

    .input-group{
      padding: 0;

      &.dropdown{
        height: 45px;
        margin-top: 20px;
      }

      .label-search{
        display: none;
      }

      .form-control{
        width: 100% !important;
      }
    }

    .form-select.child {
      height: 40px;
    }
  }

  .fixed-top{
    width: 100%;
  }

  #svg-header{
    margin-top: 190px !important;
  }

  nav.navbar.navbar-expand-xl #center-nav{
    margin-right: 0;
    padding: 0;
  }

  .col-lg-3.properties-price-absolute {
    width: 300px !important;
  }

  .img-100 {
    padding: 8px;
    height: 100vw;
    width: auto;
  }

  #center-nav a:not(.tracol-logo){
    margin: 0 auto;
  }

  .svg-inline--fa.fa-w-14{
    margin-top: 0 !important;
    top: 0px;
  }

  .navbar-toggler-icon{
    .svg-inline--fa.fa-w-14{
      margin-top: 0 !important;
      top: 5px;
      left: 5px;
    }
  }

  .arrows-slides{
    right: 20px;
  }

  .properties-area{
    width: 30vw;
  }

  .container-properties-header.first{
    display: none;
  }

  .property-detail-header{
    flex-direction: column;
    //width: 100vw;
  }

  #link-palyer{
    margin: 0;
    position: relative;
  }

  .phone-contact {
    display: none !important;
  }
  .property-promotion {
    margin-top: 85px;
  }
  #form-search-mobile .selectArrow {
    margin-bottom: 25px;
  }

  .col-lg-8.mx-auto.div-svg-header.pre-section {
    flex-wrap: wrap;
  }
  .sub-search-div {
    width: 100% !important;
  }
  #search-mobile {
    margin-top: 17px;
  }

  .col-lg-8.mx-auto.div-svg-header {
    flex-wrap: wrap;
    /*padding-left: 0 !important;
    width: calc(100% - 10px);*/
  }

  #movie {
    position: initial!important;
  }

  #link-palyer #movie {
    margin:15px 0 15px 0!important;
  }

  section.newsletter {
    //display: none;
  }

  #home-sale {
    align-items: flex-end;
  }

  #home-sale .see-video-mobile.col-lg-3 {
    width:50%;
  }

  #results-search-link {
    margin-top: 50px;
    padding-left: 10px;
  }

  .sub-search-input {
    width: 100% !important;
    height: 45px;
  }

  #rooms-drop {
    height:45px!important;
  }

  #div-newsletter-mobile {
    display: none;
  }

  header.header-home {
    height:70vh!important;
  }


  #projects .img, #references-home .img-tmp {
    height:250px;
  }

  .col-lg-12.div-form.selectSub {
    width: 100%;
  }

  .property-span-search-mobile {
    background-color: transparent;
    border: none;
    color: white;
    font-family: "AccordAlternateBold", "serf";
  }

  .property-span-search-mobile svg {
    color: white;
    border-color: white;
  }

  .color-h1-menu-mobile {
    font-family: "AccordAlternateBold", "serif";
    font-size: 28px;
    color: white;
  }
  .color-menu-mobile {
    font-family: "AccordAlternate", "serif";
    font-size: 16px;
    color: white;
  }


  #exampleModul1 .modul-header {
    border-bottom: none;
  }

  .modul-backdrop {
    z-index: 0 !important;
  }

  #exampleModul1 .modul-content {
    background-color: #f6a400;
  }

  .navbar.navbar-expand-xl.fixed-top {
    height: 70px!important;
    position: fixed;
  }

  .navbar-expand-xl .navbar-collapse {
    height:70px!important;
  }

  .property-span-arrow {
    margin-left: 0px;
  }
  .reference {
    margin-top: 130px !important;
  }
  .numbers {
    margin-top: 80px !important;
  }

  .svg-inline--fa.fa-arrow-right.fa-w-14.property-arrow:hover {
    color: #f6a400;
    border-color: #f6a400;
  }
  .svg-inline--fa.fa-arrow-right.fa-w-14.property-arrow:hover #movie {
    color: #f6a400;
  }

  .header-home {
    margin-top: 0px;
  }

  .date-ref {
    display: none;
  }

  .d-flex.flex-column.flex-shrink-0.bg-light.black {
    display: none !important;
  }

  #div-newsletter {
    display: none !important;
  }



  .logo {
    margin-right: 0px;
  }

  .svg-inline--fa.fa-bars.fa-w-14 {
    margin-top: 4px;
    color: #242424;
  }

  .navbar-collapse {
    height: calc(100vh - 50px);
    transition: height 0.5s;
    background-color: #fff;
  }

  #form-search-normal {
    display: none;
  }

  #svg-header {
    margin-top: 290px;
  }

  #header-text-home {
    font-size: 32px !important;
  }


  .navbar-collapse > ul {
    text-align: center;
    margin-top: 10px;
    //margin-left: 50px;
  }

  #submit-search-mobile {
    display: block;
  }

  .search-button {
    margin-top: 0px;
    margin-left: 0px;
  }

  #home-section {
    display: flex;
    flex-wrap: wrap;
  }

  #svgLine {
    display: none;
  }

  .project-title {
    font-size: 20px;
  }
  .pre-section-content {
    padding-left: 0;
    margin-top: -7px;
  }

  .slick-slider .slick-track, .slick-slider .slick-list {
    height: 100%;

  }

  .slick-slide {
    height: 100%;
    //width: 100% !important;
  }

  .slick-slide img{
    width: 80%;
    height: auto;
    min-width: 250px;
    max-width: 2000px;
  }

  .projects-link {
    display: none;
  }
  .projects-link-mobile {
    display: flex !important;
  }
  #home-sale{
    flex-wrap: wrap;
  }
  .property-name {
    font-size: 20px;
    font-family: "AccordAlternateBold", "serif";
    margin-bottom: 0;
  }

  .see-video {
    display: none !important;
  }
  .see-video-mobile{
    display: flex !important;
    margin-bottom: 15px;
  }
  #div-newsletter-mobile .newsletter-title {
    text-align:center;
  }
  #button-reference {
    display: none !important;
  }

  #button-reference-mobile {
    display: flex !important;
  }

  #swiper {
    display: none;
  }
  #reference-home-mobile {
    display: flex !important;
  }

  .header-tracol {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 500px;
    min-height: 100%;
  }


  .tracol-immo-name {
    font-size: 26px;
  }
  .tracol-title > h2, .tracol-title > .h2 {
    font-size: 32px;
  }

  .tracol-title {
    width: 100%;
  }
  .tracol-immo-name {
    width: 100%;
  }
  .col-lg-6.tracol-title > p {
    font-size: 16px;
    font-family: "AccordAlternate", "serif";
  }

  .tracol-page-img {
    width: 100%;
  }

  .tracol-title > p {
    padding-right: 25px;
  }
  #accorrdion {
    margin-left: -17px;
  }
  #footer-menu {
    display: none;
  }
  #footer-menu-mobile {
    display: flex !important;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .link-footer-menu {
    color: white;
  }
  .link-footer-menu:hover {
    color: white;
  }

  .header-habiter-title.col-lg-6 {
    font-size: 24px;
  }

  #property-header {
    display: none !important;
  }
  #property-header-mobile {
    display: flex !important;
    margin-top: 100px;
  }
  #sliderHeader {
    height: 100vh;
  }
  .col-lg-6.mx-auto.div-svg-header.pre-section {
    display: flex;
    flex-wrap: wrap;
    margin-top: -185px;
  }

  #link-more {
    margin-right: 20px;
  }

  .btn.btn-primary.all.border-radius.projectButton {
    height: 43px;
    margin: 3px;
    font-family: "AccordAlternateBold", "serif";
  }

  .btn.btn-primary.come.border-radius.projectButton {
    height: 43px;
    margin: 3px 0;
    font-family: "AccordAlternateBold", "serif";
  }

  .btn.btn-primary.all.border-radius.projectButton {
    height: 43px;
    margin: 3px 0;
    font-family: "AccordAlternateBold", "serif";
  }

  .btn.btn-primary.come.border-radius {
    height: 43px;
    margin: 3px;
    font-family: "AccordAlternateBold", "serif";
  }
  .btn.btn-primary.all.border-radius {
    height: 43px;
    margin: 3px;
    width: 100%;
    font-family: "AccordAlternateBold", "serif";
  }
  .btn.btn-primary.come.border-radius:hover {
    height: 43px;
    margin: 3px;
    font-family: "AccordAlternateBold", "serif";
  }
  .btn.btn-primary.all.border-radius:hover {
    width: 100%;
    height: 43px;
    margin: 3px;
    font-family: "AccordAlternateBold", "serif";
  }

  .col-lg-6.mx-auto.div-svg-header {
    display: flex;
    flex-wrap: wrap;
  }

  #button-property {
    display: none !important;
  }

  #button-property-mobile {
    display: flex !important;
  }

  #button-property-page {
    display: none !important;
  }

  #button-property-page-mobile {
    display: flex !important;
  }

  .habiter-title.mb-5 {
    font-size: 16px;
  }

  #display-none-mobile {
    display: none !important;
  }

  #img-property-section {
    padding: 0;
    width: 100%;
  }
  #link-none {
    display: none !important;
  }

  #link-ok {
    display: flex !important;
    padding-left: 15px;
    margin-bottom: -67px;
    margin-top: -85px;
  }

  #habiter-content-id {
    margin-top: -185px;
  }

  #div-hover-2:hover, #div-hover-2:focus, #div-hover-2:active, #div-hover-2.open {
    width: 190px !important;
    //height: 70px !important;
  }
  #div-hover-2 {
    //width: 190px !important;
    //height: 70px;
    bottom: 9px !important;
  }
  .project-name {
    font-size: 17px;
  }

  .city {
    font-size: 14px;
  }

  .col-lg-3.properties-price-absolute {
    position: absolute !important;
    bottom: 9px !important;
    left: 199px !important;
  }

  #filter-desktop {
    display: none !important;
  }
  #filter-mobile{
    display: none !important;
    width: 98%;
  }


  .label-search-filter {
    position: absolute;
    z-index: 1;
    color: white;

    padding-left: 5px;
    padding-top: 27px;
    width: 100%;
    text-align: center;
  }

  .select-filter {
    color: white;
    font-family: "AccordAlternateBold", "serif";
    font-size: 16px;
    text-align: left;
    text-transform: uppercase;
    background-color: transparent;
    border-color: transparent;
    padding-left: 10px;
    margin-bottom: 8px;
  }

  .select-filter > svg {
    margin-right: 5px;
  }

  .select-filter:hover > .svg-inline--fa.fa-times.fa-w-11 {
    transform: rotate(60deg);
    color: #e57c01;
  }
  .select-filter:hover {
    color:#e57c01;
  }



  #filters {
    width: 336px !important;
    height: 30px !important;
    background-color: #5f5f5f;
    border: none;
    margin-left: -27px;
  }
  #form-search-filter-ajax {
    background-color: #f6a400;
    color: white;
    top: 75px;
    left: 2px;
  }

  .dropdown::after{
    display: none;
  }

  .selectSubFilter {
    position: relative;

  }

  .selectSubFilter:after {
    content: '\f078';
    font-family: 'Font Awesome 5 Free';
    color: white;
    padding: 9px 0px 0px 0px;
    border-left: none;
    position: absolute;
    pointer-events: none;
    background-color: black;
    margin-left: 242px;
    height: 28px;
    right: 15px;
    font-weight: 900;
    display: none;
  }

  .selectSubFilter select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    float: right;
    color: white;
    background-color: #5f5f5f;
    background-image: none;
    border: none;
    -ms-word-break: normal;
    word-break: normal;
    border-radius: 0px;
  }

  .select-filter-active {
    color: black;
  }
  .select-filter-active > svg {
    color: #c48300;
  }

  .header-habiter-div {
    margin-top: 115px;
    padding-left: 15px;
  }
  .header-habiter-div2 {
    margin-top: 115px;
    padding-left: 15px;
  }

  .header-content {
    color: #ffffff;
    font-family: "AccordAlternate", "serif";
    font-size: 17px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    line-height: 24px;
    text-align: left;
    text-shadow: 2px 1px gray;
    padding-right: 25px;
  }


  #button-project-page {
    display: none !important;
  }

  #button-project-page-mobile {
    display: flex !important;
  }


  #filter-project-desktop {
    display: none !important;
  }
  #filter-project-mobile {
    display: none !important;
    width: calc(100% - 15px);
    margin-left: 9px;
  }

  .col-lg-6.mx-auto.div-svg-header.content-projet {
    margin-top: -65px;
  }

  .last-section-project {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .last-section-project > .col-lg-3 {
    margin-left: 0px !important;
  }

  #button-reference-page-mobile {
    display: flex !important;
  }

  #button-reference-page {
    display: none !important;
  }

  .header-habiter-title.col-lg-12 {
    font-size: 24px;
  }

  #button-news-page{
    display: none !important;
  }
  #button-news-page-mobile {
    display: flex !important;
  }

  #news-header {
    display: none !important;
  }
  #div-newsletter-mobile  {
    display: flex !important;
    background-color: #f6a400;
    flex-direction: column;
    height: 200px;
  }

  #section-projet-margin {
    margin-right: 45px;
  }
  .col-lg-4.job-speculative-application {
    margin: 15px !important;
  }

  .col-lg-8.mx-auto.div-svg-header.pre-section {
    flex-wrap: wrap;
  }

  .property-span-arrow-jobs {
    padding-left: 15px;
  }

  .newsletter-title {
    color: #ffffff;
    font-family: "AccordAlternateBold", "serif";
    font-size: 18px;
    text-align: left;
    //margin-left: 20px;
  }

  .col-lg-3.header-content {
    text-shadow: 0px 0px 10px rgba(0,0,0,.4), 0px 0px 10px rgba(163, 115, 115, 0);
  }

  #flick10{
    display: none !important;
  }

  .job-vacancy {
    display: none !important;
  }

  #jobs {
    display: none !important
  }

  #job-section {
    display: none !important;
  }
  .job-vacancy-mobile {
    display: flex !important;
  }

  .job-vacancy-mobile > .jobs {
    background-color: #f6a400;
    width: 100%;
    position: absolute;
    //top: 295px;
    bottom: 0;
    height: 51px;
  }

  #jobs-select {
    height: 100%;
    background-color: #f6a400;
    color: white;
    font-family: "AccordAlternateBold", "serif";
    font-size: 16px;
  }

  .selectArrowJobs {
    position: relative;
    width: 100%;
  }

  .selectArrowJobs:after {
    content: '\f078';
    font: normal normal normal 20px/1 FontAwesome;
    color: white;
    right: 1px;
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 0px;
    padding-left: 15px;
    border-left: none;
    position: absolute;
    pointer-events: none;
    height: 51px;
    background-color: #c48300;
    display: none;
  }

  .selectArrowJobs select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    float: right;
    color: #dedede;
    font-size: 16px;
    font-family: "AccordAlternate", "serif";
    background-color: #ffffff;
    background-image: none;
    border: none;
    -ms-word-break: normal;
    word-break: normal;
  }

  #jobs-mobile {
    display: block !important;
  }

  #apply-job-none {
    display: none !important;
  }

  .property-span-arrow-jobs {
    margin-top: 5px;
    color: #f6a400;
    font-family: "AccordAlternateBold", "serif";
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .property-span-arrow-jobs > svg {
    color: white;
    border-color:#f6a400;
    background-color: #f6a400;
  }


  #header-habiter {
    margin-top: 50px !important;
    margin-bottom: 20px !important;
  }
}

.slick-arrow {
  visibility: hidden;
}

.footer-nav-mobile {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: -285px;
  width: 100vw;
}

#footer-nav-mobile{
  .nav-link{
    margin: 0 0 0 15px;
  }

  .fa-facebook-f{
    margin-top: 3px;
  }

  #svg-phone{
    margin-top: 9px;
  }
}

.add-opacity-mobile {
  opacity: 1 !important;
  transition: opacity 3s !important;
}

.col-lg-3.properties-price-absolute {
  position: absolute;
  bottom: 8px;
  left: 300px;
  width: calc(100% - 307px);
}

#property-content-p > p {
  font-family: "AccordAlternate", "serif";
  padding-right: 50px;
}


#header-habiter {
  margin-top: 104px;
  margin-bottom: 120px;
}

#tracol-title-padding {
  padding-right: 50px;
  margin-top: 15px;
}

.stickyY {
  position: fixed !important;
  display: none;
  top: 109px;
  right: 281px;
  z-index: 999999999999999999999999999;}

.stickyYForm {
  position: fixed !important;
  top: -83px;
  transition: top 2s !important;
  left: 0;
  z-index: 111;
}

#last-section-locative > p > strong {
  color: #f6a400 !important;
}

#last-section-locative {
  font-family: "AccordAlternate", "serif";
  font-size: 16px;
}

#section-2-locative {
  font-family: "AccordAlternate", "serif";
  font-size: 16px;
}

#section-2-locative h3 {
  font-family: "AccordAlternateBold", "serif";
  color: #f6a400;
  font-size: 18px;
}

#section-2-locative samp {
  font-size: 18px;
  background-color: #f7e7cb;
  color: #f6a400;
  font-family: "AccordAlternateBold", "serif";
}

#last-section-locative  h2 {
  font-family: "AccordAlternateBold", "serif" !important;
  font-size: 32px !important;
  margin-top: 10px;
}

.numbers {
  margin-top: 190px;
  position: relative;
  z-index: 0;
}

#contact-link {
  margin-left: 0px !important;
}

.send-display {
  display: block !important;
  position: absolute;
}

#send {
  height: 75px;
  margin-top: -38px;
  background-color: #f6a400;
  border-color: #f6a400;
}
.sub-search-div-height {
  height: 95px !important;
}

#img-card {
  position: fixed;
  bottom: 0px;
  right: 324px;
}

@media (max-width:1755px) {
  nav .tracol-logo {
    margin-left: 15px;
    margin-right: 150px;
  }
}

@media (max-width:1500px) {
  .navbar-expand-xl .navbar-collapse {
    height: 70px;
  }
}
@media (max-width:1400px) {
  .property-title{
    width: 60% !important;
  }
}

@media screen
and (min-device-width: 1199px)
and (max-device-width: 1600px)
and (-webkit-min-device-pixel-ratio: 1) {

  .stickyYForm{
    top: -33px;
  }

  #form-search-normal {
    width: 50%;
  }

  .col-lg-6.pre-section-content{
    padding-right: 0;
  }

  #header-text-home {
    //margin-left: -141px !important;
  }

  #svg-home {
    margin-left: -138px !important;
  }
  .height-523 {
    height: 420px;
  }
  #link-palyer {
    justify-content: flex-start;
    margin-top: 0px;
    display: flex;
  }
  #link-palyer #movie {
    margin-left: 0px;
  }
  #link-more {
    width: 139% !important;
  }
  .height-380 {
    height: 370px;
  }
  .col-lg-12.div-svg-header.last-section-project {
    display: flex;
    margin-left: 36px;
  }
  .property-span-arrow {
    width: 100% !important;
  }
  .number-content h2 {
    font-size: 23px !important;
  }
  .img-number {
    display: none !important;
  }

  .nav-item {
    margin-right: 5px !important;
  }
  .stickyY {
    right: 109px;
  }

  .header-tracol {
    height: 770px;
    margin-top: 0px;
  }

  .less-size-header {
    height: 450px;
  }


  #header-tracol-mobile {
    background-size: cover;
    margin-top: 60px;
    //margin-bottom: -245px;
  }

  .tracol-immo-name {
    margin-left: 0;
  }
  .tracol-wrap > .tracol-title {
    margin-right: 75px;
  }
  #header-job {
    max-height: 640px;
  }

  #section2-tracol {
    margin-left: 80px;
  }
  #jobs-delete-mobile samp {
    font-family: "AccordAlternate", "serif";
  }
  .col-lg-8.mx-auto.apply-job > .property-span-arrow-jobs:hover {
    color: #f6a400;
  }
  #link-none-a {
    margin-top: -19px;
    margin-left: 0px;
  }
  #sliderHeader {
    height: 86vh !important;
  }

  .job1-span-arrow {
    margin-top: -27px;
    font-size: 16px !important;
    margin-left: 20px;
  }
  .number-content {
    //margin-right: 70px;
  }


  .tracol-title > h2, .tracol-title > .h2 {
    margin-top: -21px;
  }
  .properties-link {
    padding-left: 11px;
  }

  #header-habiter {
    margin-top: -96px;
  }
}


#jobs-delete-mobile samp {
  font-family: "AccordAlternate", "serif";
}

/* ----------- Retina Screens ----------- */
@media screen
and (min-device-width: 1600px) {

  #link-palyer {
    margin-top: -23px;
  }

  #link-palyer {
    justify-content: flex-start;
    margin-top: 0px;
    display: flex;
  }

  #links-ref-home {
    margin-left: 60px;
  }

  #link-palyer #movie {
    margin-left: 0px;
  }

  .stickyY {
    position: fixed !important;
    top: 147px;
    right: 105px;
    z-index: 1000000000000000300000000000;
  }

  #SVGDoc {
    //margin-bottom: -60px;
  }

  #link-none {
    padding-left: 0 !important;
  }

  #section-projet-margin {
    padding-left: 0;
    margin-right: 40px;
    width: auto;
  }

  #apply-job-none {
    margin-left: -27px;
  }

  .header-tracol {
    height: 700px;
    margin: initial;
  }
  .col-lg-6.pre-section-content {
    padding-right: 0;
  }
}

#sub-search-div {
  input {
    text-indent: 10px;
  }
  .undroped-header {
    padding-left:10px;
    font-size:0.8em!important;
  }
  .undroped-header, input {
    font-size:1.1em;
  }
  .undroped {
    display: flex;
    align-items:center;
  }
  .div-form {
    text-align: center;
  }
  .sub-search-input::placeholder {
    font-family: "AccordAlternate";
    color:#5f5f5f;
  }
  .undroped-header {
    opacity:1;
  }
}

.accordion-button.collapsed{
  color: #bfbfbf !important;
}

#display-none-mobile {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
#jobs-delete samp {
  font-family: "AccordAlternate", "serif";
}

.header-home {
  height: 95vh !important;
}

@media (max-width: 1580px) {
  .tracol-content {
    width:90%!important;
  }
}
@media (min-width:1200px) and (max-width:1500px) {
  #propertiesOther .slick-track {
    height:200px!important;
  }
}

@media (max-width: 1200px) {
  .properties-slide .slick-list {
    height:265px!important;
  }
  nav .tracol-logo {
    margin:auto!important;
  }
}
@media (max-width: 768px) {
  #formResearchNav {
    display:none!important;
  }
  #submit-search-mobile {
    display:block!important;
  }
  nav.navbar {
    z-index:9999;
  }
  nav #center-nav {
    background: #fff;
  }
  #exampleModal1 .modal-lg {
    width:100vw;
    height:100vh;
    margin:0;
  }
  #exampleModal1 .modal-content {
    width:inherit;
    height:inherit;
    padding:30px;
  }
  form .container-form-mobile {
    width: 75%;
    margin: auto;
  }
  form .container-form-mobile .selectors {
    flex-direction: column;
    width:inherit;
  }
  #exampleModal1 #form-search-normal-mobile {
    margin-top:80px;
  }
  form .container-form-mobile #transaction-drop, form .container-form-mobile #property-drop, form .container-form-mobile #location-drop-mobile {
    width:100%;
    margin-bottom:15px;
  }
  form .container-form-mobile #transaction-drop {
    z-index:999999;
  }

  form .container-form-mobile #property-drop {
    z-index:99999;
  }

  form .container-form-mobile #location-drop-mobile {
    width:100%;
    margin-bottom:15px;
    z-index:9999;
  }
  form #sub-search-div-mobile .div-form, form #sub-search-div-mobile #rooms-drop  {
    margin-bottom:15px!important;
  }
  form #sub-search-div-mobile #rooms-drop {
    z-index:99;
  }
  form #submit-search {
    float:right;
  }

  .col-lg-8.mx-auto.div-svg-header {
    overflow: hidden;
  }
  .img.slick-slide {
    height:300px;
  }
  #properties {
    width:100%;
  }
  #properties .slick-track {
    display: flex;
  }
  #properties .properties-slide .img:not(.img:first-child, .img.slick-slide), #propertiesOther .properties-slide .img:not(.img:first-child) {
    display: none;
  }
  .other-properties .div-svg-header {
    padding-left:10px!important;
  }

  #propertiesOther .items {
    margin-left:10px;
  }

  #propertiesOther .items, #properties .items {
    width:auto;
  }
  #propertiesOther .properties-price, #properties .properties-price {
    width:auto;
    padding-right:30px;
  }
  #properties .properties-price {
    padding-right: 43px;
  }
  #button-property-page-mobile .flicking-viewport {
    display: none;
  }

  #propertiesOther.vendre .properties-slide .img {
    display: block!important;
  }
  .title-vendre-other {
    padding-left:10px;
  }
  #propertiesOther .properties-slide .img, #propertiesOther .properties-slide .img:not(.img:first-child) {
    display: block!important;
  }
  #button-project-page-mobile .flicking-camera {
    width:90%;
    margin:auto;
  }
  #button-project-page-mobile .project.filter .input-filter {
    margin-right:0;
    width:30%;
  }
  .last-section-project {
    margin-top:25px;
    margin-bottom:25px;
  }
  section.project {
    margin-top:0!important;
  }
  #button-project-page-mobile {
    margin-bottom:20px;
  }
  #button-project-page-mobile .flicking-camera {
    flex-direction: row!important;
  }
  #button-project-page-mobile .flicking-camera {
    width:90%;
    margin:auto;
  }
  #button-project-page-mobile .project.filter .input-filter {
    margin-right:0;
    width:30%;
  }
  .last-section-project {
    margin-top:25px;
    margin-bottom:25px;
  }
  .projectButton.all .span-projects {
    color:#fff;
  }
  .projectButton:not(.projectButton.all) {
    background: transparent;
  }
  .div-hover-2.open {
    height:80%!important;
  }
  .div-hover-2.open .project-content {
    visibility: visible!important;
  }
  .teams .list-categories .project-title .separe {
    display: none;
  }
  .teams .list-categories .tracol-title .tracol-content {
    margin-top:15px!important;
    margin-bottom:50px;
    font-size: 1.1em;
  }
  #references-home, #flick100, .flicking-camera #flick100 {
    margin-top:0!important;
  }
}

#contact_form_message {
  background: #f5f5f5;
}


@media only screen and (max-width: 576px) {
  .project.filter .input-filter {
    margin-right:0!important;
  }
  section.project.filter #button-project-page-mobile.div-svg-header {
    margin-top:0!important;
  }
  #form-search-normal-mobile {
    .dropdown .droped .items {
      box-shadow: grey 0px 5px 5px;
    }
    input#budget {
      color: #F6A400;
      text-indent: 10px;
    }
    .undroped-subheader {
      margin-left:10px;
    }
    .undroped-subheader.good-place {
      margin-top:0!important;
    }
    #rooms-drop.dropdown .undroped::after, #area-drop.dropdown .undroped::after {
      top:3px!important;
    }
    .search-button {
      margin-top: 20px!important;
    }

    #sub-search-div-mobile {
      .undroped-header {
        padding-left:10px;
      }
    }
  }

  .desc-property {
    .modal.show .modal-dialog {
      margin-top:80px;
    }
  }
  .teams {
    margin-top: 0!important;
  }
  #properties .properties-slide {
    height:auto!important;
    padding-right:0!important;

    .img:not(#properties .properties-slide .img.slick-slide) {
      width:100%!important;
    }
  }
  .teams .list-categories {
    margin-top:80px;
  }

  #properties .div-img-hover-2 {
    margin:auto;
  }

  #properties .properties-price {
    padding-right:15px!important;
  }
  .header-habiter.slider-not-home {
    width:100%;
  }

  .header-habiter.slider-not-home {
    margin-top: 150px !important;
    width: 100%;
    margin: auto;
    height: 350px!important;

    .carousel.pointer-event {
      height:inherit;
    }
  }

  section.items-property-single .item-single {
    width:25%;
  }

  .description-prop-container .description-prop {
    padding:0 15px;
  }
}

@media (max-width: 480px) {

  #section-1-tracol{
    margin-top: 130px;
  }
  .col-lg-3.properties-price-absolute {
    width: calc(100% - 199px) !important;
  }

  .tag-ref-projet-2 {
    right: 0;
    top: 9px;
    padding-top: 4px;
  }

  #projects .tag-ref-projet-2 {
    right: 6px!important;
    top: 9px;
    padding-top: 4px;
  }

  .div-img-hover-2 {
    width: calc(100% - 10px);
  }

  .display-flex{
    //padding-left: 0;
  }

  .table > tbody > tr{
    //flex-direction: column;
    display: flex;
  }

  .flicking-camera .number-content {
    width: 100% !important;
  }

  #interior-div-project-detail {
    margin-bottom: 20px;
    display: flow-root;
    position: relative;
    overflow-x: scroll;
    height: 600px;

    > table{
      position: absolute;
    }
  }

  .div-img-hover-2 {

    &.news{

      .tag-ref-projet-2{
        right: 5px !important;
      }

      .img.news{
        width: calc(100% - 14px) !important;
        margin-left: 9px !important;
      }
    }
  }

  .ref-container .modul .modul-dialog, #reference .modul .modul-dialog {
    margin: 120px auto;
  }

  @media(hover: hover) and (pointer: fine) {
    .div-img-hover-2 {

      &.news{

        .tag-ref-projet-2{
          right: 5px !important;
        }

        .img.news{
          width: calc(100% - 14px) !important;
          margin-left: 9px !important;
        }
      }
    }
  }

  .container-fluid{
    //padding: 0 !important;
  }

  .arrows-slides{
    left: 20px;
    bottom: 110px;
  }

  .svg-inline--fa.fa-w-14 {
    margin-top: auto !important;
    top: 4px;
  }

  .img-100.height-600 {
    width: 100%;
    height: 380px;
  }

  #filters{
    width: 100% !important;
    border: none;
    margin-left: 1px;
  }

  .dropdown{
    padding: 0;
    //width: calc(100vw - 20px) !important;
  }

  #form-search-filter-ajax{
    top: 70px;
  }

  .come{
    width: auto;
  }

  .img:not(.img.slick-slide){
    width: calc(100% - 5px) !important;
  }

  .properties-slide .arrow-right {
    right:0!important;
  }

  .infos-person {
    display: none !important;
  }

  #footer-nav-mobile{
    .nav-link{
      margin: 0 0 0 15px;
    }

    .fa-facebook-f{
      margin-top: 3px;
    }

    #svg-phone{
      margin-top: 9px;
    }
  }

  .flicking-camera {
    width: calc(100% - 5px) !important;
  }

  .navbar-toggler:focus{
    box-shadow: none !important;
  }

  .reference{
    margin-top: 20px !important;
  }

  .home-sale-img{
    height: 300px;
    background-position: center;
  }

  .selectSubFilter::after {
    content: "\f078";
    color: white;
    padding: 4px 4px 4px 4px;
    border-left: none;
    position: absolute;
    pointer-events: none;
    background-color: black;
    margin-left: 119px;
    height: 28px;
    right: 21px;
    font-weight: 900;
    font-family: 'Font Awesome 5 Free';
    top: 759px;
  }

  .select-filter > svg {
    transform: rotate(135deg);
  }

  .select-projet-active {
    color: #c48300 !important;
  }
  .select-filter.select-projet-active > svg {
    transform: rotate(90deg);
  }

  #movie {
    display: none;
  }

  #property-content-p > p{
    padding-right: 0;
  }

  .height-257 {
    height: calc(100vw - 106px) !important;
  }

  .flicking-camera{
    .separator{
      display: none;
    }

    .number-content{
      width: 100%;
      margin: 30px 0;
      align-items: flex-start !important;

      h2{
        line-height: .5;
        font-size: 48px !important;
      }
    }
  }

  .navbar-collapse{
    .nav-item{
      margin-top: 1vh;
    }
  }

  .footer-nav-mobile{
    flex-direction: column;
    margin-top: -308px;
    width: 100vw;
  }

  #svg-video-mobile {
    display: flex !important;
    position: absolute;
    top: 248%;
    right: 50%;
    height: 39px;
    width: 12%;
    color: white;
    border: 2px solid white;
    padding: 8px;
  }
  #accordionExample {
    margin-left: 22px;
  }

  #exampleModul .modul {
    width: 98%;
  }

  .tracol-wrap {
    flex-wrap: wrap;
  }

  .tracol-title{
    //margin-top: 0;
    padding-left: 0 !important;
  }

  .col-lg-12.tracol-wrap {
    margin-top: -90px;
  }

  .accordion-flush .accordion-item .accordion-button {
    font-size: 18px !important;
  }

  #filter-mobile::after {
    content: "\f078";
    color: white;
    padding: 4px 4px 4px 4px;
    border-left: none;
    position: absolute;
    pointer-events: none;
    background-color: black;
    margin-left: 119px;
    height: 28px;
    right: 21px;
    font-weight: 900;
    font-family: "Font Awesome 5 Free";
    top: 842px;
    display: none;
  }

  #habiter-content-id {
    margin-top: -110px;
  }

  .header-habiter-title.col-lg-8 {
    font-size: 22px;
  }

  #content-header-property{
    margin-top: -64px;
  }

  #sliderHeader {
    height: 83vh;
  }

  #jobs-content-mobile {
    padding: 15px;
  }

  #jobs-content-mobile samp {
    font-family: "AccordAlternate","serif";
  }

  #jobs-content-mobile {
    padding: 15px;
    font-family: "AccordAlternate","serif";
  }

  #div-form-contact {
    flex-direction: column;
  }

  .header-habiter {
    height: 56vh;
  }
  #section-1-locative {
    width: 100%;
    margin-top: 10px;
  }
  #flex-column{
    flex-direction: column;
  }
  #section-2-locative {
    font-family: "AccordAlternate", "serif";
    font-size: 16px;
    width: 70%;
  }
  #header-tracol-mobile {
    //background-size: contain !important;
  }
}

@media (max-width: 991px) {
  .padd-to-left, .padd-to-right {
    padding-left:10px!important;
    padding-right:10px!important;
  }
}
@media (max-width: 840px) {
  #projects .img {
    width: 100%!important;
  }
  #projects .img {
    width: 100%;
  }
  .tag-ref-projet-3 {
    right:0!important;
  }
  .flicking-viewport{
    width: 100%;
  
    .flicking-camera{
      flex-wrap: wrap;
      justify-content: space-between !important;
    
      .number-content {
        font-family: "AccordAlternateMedium", "serif";
        margin-bottom: 60px;
    
        h2{
          font-size: 8vw !important;
          font-family: "AccordAlternateBold", "serif";
          text-align: center;
          width: 100%;

          strong{
            opacity: 0;
            transition: all 1s;
    
            &.count{
              opacity: 1;
            }
          }
    
          .sqmeters{
            font-size: 2.5vw;
          }
        }
    
        p{
          font-size: 3vw !important;
          font-weight: 100;
          margin-bottom: 0;
          text-align: center;
          width: 100%;
    
          &:first-child{
            margin-bottom: 20px;
          }
        }
    
        /*&.constr{
          width: 190px;
        }
    
        &.comm{
          width: 190px;
        }
    
        
    
        &.coll{
          width: 190px;
        }*/
    
        &.porte{
          width: 16vw;
        }
      }
    
      .separator{
        margin: 3vw 1vw 0;
      }
    }
  }

  #projects {
    flex-direction: column;
  }
  #form-search-mobile div {
    width: 100%;
  }
  #form-search-mobile .selectors {
    flex-direction: column;
  }
  #form-search-mobile #transaction-drop {
    width: 100%;
    z-index: 11111111;
    margin: 10px;
  }
  #form-search-mobile #property-drop {
    width: 100%;
    z-index: 4321111;
    margin: 10px;
  }
  #form-search-mobile #location-drop-mobile {
    width: 100%;
    z-index: 111111;
    margin: 10px;
    margin-bottom: 50px;
  }
  #form-search-mobile #sub-search-div {
    flex-direction: column;
    margin-top: 80px;
  }

  #form-search-mobile #submit-search {
    position: absolute;
    bottom: 20px;
  }
  #form-search-mobile #rooms-drop {
    margin-top: 20px;
  }

  .sticky-text{
    position: absolute;
    //top: 50vh;
    //left: calc(50vw - 132px);

    top: 28vh;
    left: calc(50vw - 87px);
  }

  .ref-container{
    flex-direction: column;
  }

  .sqmeters{
    font-size: 24px !important;
  }

  .reference{
    //display: none;
  }

  .job-speculative-application{
    margin-right: 0 !important;
  }

  .arrows-slides{
    right: 37px;
    bottom: 170px;
  }

  .property-detail-header{
    width: 100%;
  }

  .container-properties-header{
    flex-direction: column;
  }

  #popUpChristmas{
    display: none !important;
  }

  /*.table > tbody{
    flex-direction: column;

    > tr{
      flex-wrap: wrap;
    }
  }*/

  .tracol-title.section2{
    padding-left: 0;
  }
  .separator{
    display: none;
  }

  .header-tracol {
    margin-top: 0;
  }

  .ref-list {
    width: 100% !important;
  }

  .container-fluid .text-center{
    padding-left: 0 !important;
  }

  .tracol-wrap{
    flex-direction: column;
  }

  .image-sider{
    margin: 20px 0;
  }

  #accordionExample {
    margin-top: 35px;
  }

  #accorrdion {
    margin-left: 5px;
  }

  #div-hover-2 {
    width: 60vw !important;
  }

  #div-hover-2:hover, #div-hover-2:focus, #div-hover-2:active, #div-hover-2.open {
    width: 60vw !important;
  }

  .modul .modul-dialog .modul-content .modul-body .modul-slider .items .item{
    width: 100% !important;
  }

  .flicking-camera .number-content{
    width: 50% !important;
    margin: 0 auto;
  }

  .tag-ref{
    top: 5px;
    right: 34px;
  }

  .reference {
    margin-top: 40px !important;
  }

  .numbers {
    margin-top: 80px !important;
  }

  .property-promotion {
    margin-top: 60px;
  }

  .tag-ref-projet-3 {
    right: 5px;
  }

  .img-tmp {
    width: calc(100vw - 40px);
    height: calc(100vw - 40px);
  }

  #div-form-contact {
    flex-direction: column;
  }

  #jobs-content-mobile {
    padding: 15px;
    width: calc(100vw - 60px);
  }

  #jobs-content-mobile samp {
    font-family: "AccordAlternate","serif";
  }
  #jobs-content-mobile {
    padding: 15px;
    font-family: "AccordAlternate","serif";
  }

  .flicking-camera .number-content h2, .flicking-camera .number-content .h2{
    font-size: 48px !important;
  }

  .flicking-camera .number-content p, .flicking-camera .number-content .p{
    white-space: nowrap;
    font-size: 14px !important;
  }

  #movie {
    //display: none;
  }

  #references-home{
    .tag-ref{
      top:0;
      right:0;
    }

    .div-hover-2{
      bottom: 6px !important;
    }
  }

  .div-img-hover-2 {
    position: relative;
    overflow: hidden;
    width: calc(100% - 10px);

    &.news{
      width: calc(100% - 6px);

      .tag-ref-projet-2{
        right: 0;
      }
    }
  }


  .div-svg-header{
    margin-left: 0;
  }

  .pre-section-content {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 60px;
  }

  .img-100{
    height: auto;
    width: 100%;
  }

  .input-group{
    padding: 0;
  }

  .flicking-camera{
    //width: calc(100% - 15px) !important;
    //margin-left: 9px;
    margin-bottom: 5px;
    flex-direction: column !important;
  }

  #filters{
    width: 100% !important;
    margin-left: 0;
  }

  #svg-video-mobile {
    display: flex !important;
    position: absolute;
    top: -173px;
    //right: 50%;
    left: calc(50vw - 36px);
    height: 39px;
    width: 50px;
    color: white;
    border: 2px solid white;
    padding: 8px;
  }

  .btn.btn-primary.all.border-radius.projectButton{
    width: 100%;
  }

  .flicking-viewport{
    width: 100%;
  }

  #home-section {
    margin-bottom: -125px;
  }
  #sliderHeader {
    height: 74vh;
    margin-top: 0;
    height: calc(100vh - 120px) !important;
    height: 500px !important;
  }
  .header-habiter-title.col-lg-8 {
    font-size: 22px;
    margin-top: 100px;
  }
  .project {
    //margin-bottom: 198px !important;
    margin-bottom: 20px !important;
  }
  #flex-column{
    flex-direction: column;
  }
  #header-tracol-mobile {
    //background-size: contain !important;
    margin-top: 0;
  }

  .separator{
    display: none;
  }
  section.reference {
    padding-top:40px!important;
  }
}

@media (min-width: 769px) and (max-width: 991px) {
  .last-section-project {
    margin-top:25px;
  }
  #jobs-content-mobile samp {
    font-family: "AccordAlternate","serif";
  }
  #jobs-content-mobile {
    padding: 15px;
    font-family: "AccordAlternate","serif";
  }
  #habiter-content-id {
    margin-top: -111px;
  }

  #home-section {
    margin-bottom: -145px;
  }

  #movie {
    display: none;
  }
  .col-lg-10.job-speculative-application {
    margin-right: 25px;
    background-color: #BFBFBF;
    margin-left: 25px;
    margin-bottom: 50px;
  }

  #svg-video-mobile {
    display: flex !important;
    position: absolute;
    top: 3194px;
    right: 47%;
    height: 39px;
    width: 8%;
    color: white;
    border: 2px solid white;
    padding: 8px;
  }

  #width-col-6-home {
    width: 100%;
  }

  #header-tracol-mobile {
    background-size: cover !important;
  }
  #svg-phone {
    padding-right: 50px;
  }

  .img-number {
    display: none !important;
  }
  .number-content > h2 {
    font-size: 36px !important;
  }
  .number-content {
    margin-right: 45px;
  }

  .project {
    margin-bottom: 200px;
  }

  .selectSubFilter::after {
    content: "\f078";
    font-family: 'Font Awesome 5 Free';
    color: white;
    padding: 9px 0px 0px 0px;
    border-left: none;
    position: absolute;
    pointer-events: none;
    background-color: black;
    margin-left: 242px;
    height: 29px;
    right: 33px;
    font-weight: 900;
  }

  .select-filter > svg {
    transform: rotate(135deg);
  }

  .select-projet-active {
    color: #c48300 !important;
  }
  .select-filter.select-projet-active > svg {
    transform: rotate(90deg);
  }

  .header-habiter-title.col-lg-8 {
    font-size: 28px;
  }
  #property-header-mobile {
    max-width: 580px;
  }

  .header-habiter {
    height: 56vh;
  }
  #sliderHeader {
    height: 56vh;
    margin: initial;
  }

  .selectdiv::after {
    content: "\f063" !important;
    font-family: 'Font Awesome 5 Free' !important;
    color: white;
    right: 0px;
    padding: 9px 0px 0px 0px;
    border-left: none;
    position: absolute;
    pointer-events: none;
    background-color: black;
    height: 36px;
    font-weight: 900 !important;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {

  .img{
    margin-left: 12px;
  }

  .col-lg-6.pre-section-content{
    padding-right: 0;
  }

  .tracol-page-img2{
    width: auto;
  }

  .container-properties-header{
    width: 66.6666% !important;
  }

  .price-detail-bien{
    width: 66.6666% !important;
  }

  .col-lg-6.pre-section-content{
    padding-right: 0;
  }

  .separator{
    display: none;
  }

  .col-lg-2.see-video {
    margin-right: 50px;
  }
  #svg-phone {
    padding-right: 50px !important;
  }

  .col-lg-8.projects-link-mobile {
    margin-left: 164px;
  }
  #property-content-p {
    width: 100%;
  }

  .col-lg-3.see-video-mobile {
    width: 100%;
  }

  #movie {
    position: relative;
    top: -97px;
    right: -103px;
  }

  .col-lg-4.div-img-hover-2 {
    width: 100%;
  }

  #reduce-with {
    width: 250px;
  }

  .col-lg-5.container-properties-header {
    width: 500px;
  }
  .header-habiter-title.col-lg-8 {
    font-size: 28px;
  }

  .selectSubFilter::after {
    content: "\f078";
    font-family: "Font Awesome 5 Free" !important;
    color: white;
    right: 190px;
    padding: 7px 2px 0px 3px;
    border-left: none;
    position: absolute;
    pointer-events: none;
    background-color: black;
    height: 30px;
    font-weight: 900 !important;
  }

  #habiter-content-id {
    margin-top: -93px;
    width: 100%;
  }
  #img-property-section {
    //margin-left: 170px;
  }
  #link-ok {
    margin-left: -14px;
  }

  #header-project p {
    width: 163%;
  }

  #section-1-project p {
    width: 168%;
    margin-bottom: 50px;
  }

  #section-2-project p {
    width: 200%;
  }
  #section-projet-margin {
    width: 23%;
  }

  #section-1-reference > p {
    width: 144%;
    margin-bottom: 50px;
  }
  #sliderHeader {
    height: 56vh;
    margin: initial;
  }
  #section-1-news {
    width: 90%;
    margin-bottom: 50px;
  }

  .selectArrowJobs::after {
    content: "\f078";
    font-family: "Font Awesome 5 Free" !important;
    color: white;
    right: 1px;
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 0px;
    padding-left: 15px;
    border-left: none;
    position: absolute;
    pointer-events: none;
    height: 51px;
    background-color: #c48300;
    font-weight: 900 !important;
  }

  #jobs-content-mobile {
    padding-left: 20px;
  }

  .property-span-arrow-jobs:hover {
    color: #f6a400;
  }
  .col-lg-10.job-speculative-application {
    margin-left: 20px;
    margin-bottom: 50px;
  }

  .property-span-arrow-jobs {
    margin-left: -161px;
  }
  .header-habiter {
    height: 56vh;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {

  .nav-item {
    margin-right: 15px !important;
  }
  .navbar-nav.me-auto {
    margin-left: -110px;
  }

  .col-lg-6.pre-section-content{
    padding-right: 0;
  }

  .job-speculative-application-h3 {
    font-size: 24px;
  }
  .job-speculative-application-text {
    font-size: 15px;
  }
  .numbers {
    margin-top: 65px;
  }

  .job1-span-arrow {
    font-size: 14px !important;
    padding-left: 22px;
  }
  #form-search-normal {
    margin-left: -25px !important;
    width: 565px;
  }

  .d-flex.flex-column.flex-shrink-0.bg-light.black {
    display: none !important;
  }
  #div-newsletter {
    display: none !important;
  }
  #svg-home {
    //margin-left: -179px;
  }
  /*#block-header-home p {
    margin-left: -183px;
  }*/
  #svg-phone {
    display: none;
  }
  .col-lg-6.opacity-mobile.add-opacity-mobile {
    display: none;
  }
  .col-lg-12.opacity-mobile.add-opacity-mobile {
    margin-top: 128px;
    margin-right: 221px;
  }

  .logo {
    margin-right: 110px;
  }
  .navbar-toggler {
    color: white;
    border-color: white;
  }
  .navbar-collapse {
    height: calc(85vh - 50px);
    transition: height 0.5s;
  }

  .stickyY {
    position: fixed !important;
    top: 109px;
    right: 73px;
    z-index: 1000000000000000300000000000;
  }

  #link-palyer {
    margin-top: -13px;
    justify-content: start;
  }
  .col-lg-2.see-video {
    margin-right: 34px;
  }
  .img-number {
    display: none;
  }
  .number-content h2 {
    font-size: 44px !important;
  }
  .number-content {
    margin-right: 15px;
  }

  .number-content h2 {
    font-size: 31px !important;
  }
  #links-ref-home {
    margin-left: 45px;
  }
  #movie {
    margin-left: 10px;
  }

  .col-lg-6.col-md-12.col-sm-12.div-img-hover-2 {
    width: 100%;
  }
  #link-none-a {
    margin-top: 15px;
    margin-left: 0px;
  }

  .col-lg-12.div-svg-header.last-section-project {
    display: flex;
    flex-direction: column;
    //margin-left: 135px;
    justify-content: flex-start;
    align-items: baseline;
    margin-left: calc(16.666666vw - 6px) !important;
  }
  #section-projet-margin {
    //width: 132px;
    margin-left: 0;
  }
  #discover {
    padding-left: 10px;
  }
  #header-tracol-mobile {
    background-size: contain;
    margin-top: 18px !important;
    margin-bottom: -100px;
  }
  .col-lg-12.tracol-wrap {
    margin-left: -112px;
  }

  #section-1-tracol{
    margin-right: 160px;
  }
  #section-2-img-tracol {
    margin-right: 165px;
  }
}


#svg-imm {
  position: absolute;
  z-index: 2;
  left: 22px;
}

@media (min-width: 1440px) and (max-width: 1601px) {
  .height-380 {
    height: 380px;
  }

}
@media (min-width: 1602px) and (max-width: 1919px) {
  .stickyY {
    position: fixed !important;
    top: 110px;
    right: 105px;
    z-index: 1000000000000000300000000000;
  }
  .height-380 {
    height: 450px;
  }
}

@media (min-width: 1920px) {
  .number-content h2 {
    font-size: 72px !important;
  }
  .stickyY {
    position: fixed !important;
    top: 110px;
    right: 105px;
    z-index: 1000000000000000300000000000;
  }
  .img-100.height-380 {
    min-height: 580px;
  }
}




@media (min-width: 2000px) {
  .number-content h2 {
    font-size: 72px !important;
  }
  .number-content {
    margin-right: 0;
  }
  .img-100.height-380 {
    min-height: 580px;
  }
}

@media (min-width: 2200px) {
  .number-content h2 {
    font-size: 72px !important;
  }

  .stickyY {
    position: fixed !important;
    top: 109px;
    right: 105px;
    z-index: 1000000000000000300000000000;
  }
  .img-100.height-380 {
    min-height: 693px;
  }
}


.col-lg-6.projects-link {
  margin-left: 75px;
}

.img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 450px;
  margin: 9px;
  //margin-right: 93vw;
  width: calc(100% - 16px);

  &.news{
    width: 100%;
  }
}

#jobs-delete > h2, #jobs-delete > .h2 {
  font-family: "AccordAlternateBold", "serif";
}
#jobs-delete strong {
  font-family: "AccordAlternateBold", "serif";
}

#link-palyer #movie {
  margin-left: 15px;
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
  box-shadow: none !important;
}

.alert.alert-danger {
  margin-top: 144px;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  z-index: 1;
}
.alert.alert-success {
  margin-top: 144px;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  z-index: 1;
}

@media (max-width: 320px) {
  .img{
    width: calc(100% - 5px) !important;
  }

  #div-hover-2 {
    width: 50% !important;
  }

  .col-lg-3.properties-price-absolute {
    right: 0;
    left: auto !important;
    width: calc(50% - 9px) !important;
    padding: 0 10px;
  }

  .tag-ref-projet-2 {
    right: 0;
    top: 9px;
    padding-top: 4px;
  }

  .div-img-hover-2 {

    &.news{


      .tag-ref-projet-2{
        right: 11px !important;
      }

      .img.news{
        width: calc(100% - 20px) !important;
        margin-left: 9px !important;
      }
    }
  }

  .navbar-collapse .nav-item {
    margin: 1vh 0;
  }

  .navbar-collapse > ul{
    margin-top: 0;
  }
}




#loading {
  width: 5rem;
  height: 5rem;
  border: 5px solid #f3f3f3;
  border-top: 6px solid #c48300;
  border-radius: 100%;
  margin: auto;
  visibility: hidden;
  animation: spin 1s infinite linear;
}
#loading.display {
  visibility: visible;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#video1 {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  height: auto;
  margin-top: 30%;
  border: 12px solid black;
}

.video-pop-up-dialog {
  max-width: 50% !important;
}

.video-pop-up-content {
  height: 50vw;
  width: 50vw;
  background: transparent !important;
  border: none !important;
}

#buttonVideoFour {
  background-color: transparent;
  border: medium none;
  color: #fff;
  display: block;
  font-size: 18px;
  margin: 0 auto;
  padding: 8px 16px;
  position: absolute;
  top: calc(28vw - 48px);
  z-index: 11;
  left: calc(25vw - 48px);
  width: auto;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#buttonVideoFour.active {
  background-color: transparent;
}
#buttonVideoFour.video-active {
  display: none !important;
}

#closeModal {
  position: absolute;
  z-index: 11;
  top: 340px;
  right: 25px;
  background-color: #f6a400;
  border-color: #f6a400;
}

.home-p > p {
  color: white;
  font-family: "AccordAlternateBold", "serif";
  font-size: 42px !important;
  text-transform: uppercase;
  text-align: left;
  line-height: 1.1;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6), 0px 0px 10px rgba(163, 115, 115, 0);
}


.hover-property-first {
  cursor: pointer;
}

.hover-property-first:hover td {
  color: #f6a400;
}

.lowercase-title {
  color: #242424;
  font-family: "AccordAlternateBold", "serif";
  font-size: 32px;
  font-weight: 700;
  text-align: left;
  font-style: normal;
  margin: 10px 60px 30px 0;
}

